import React from 'react'

import { Icon } from 'antd'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsExportData from 'highcharts/modules/export-data'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsSeriesLabel from 'highcharts/modules/series-label'
import map from 'lodash/map'

import colors from '../../../../components/hocs/colors'

highchartsSeriesLabel(Highcharts)
highchartsExporting(Highcharts)
highchartsExportData(Highcharts)

const HiringTrendsChart = ({ trends = [], referenceKey }) => {
  const options = {
    chart: {
      height: 400,
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1,
      },
      type: 'areaspline',
    },
    credits: {
      enabled: false, // this is to not show highcharts.com text
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    navigation: {
      menuItemStyle: {
        fontSize: '10px',
      },
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 1,
      },
    },
    series: [
      {
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, colors.blue1],
            [1, 'rgba(255,255,255,.5)'],
          ],
        },
        data: trends.map(trend => trend.total_hires_pct),
        lineColor: colors.blue1,
        lineWidth: 4,
        name: referenceKey,
      },
    ],
    title: {
      align: 'center',
      text: '',
    },
    tooltip: {
      valueDecimals: 3,
      valueSuffix: '%',
    },
    xAxis: {
      categories: map(trends, 'year'),
      labels: {
        overflow: 'justify',
      },
      title: {
        text: 'Year Hired',
      },
    },
    yAxis: {
      alternateGridColor: null,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: {
        text: 'Employment (% of People)',
      },
    },
  }

  return (
    <div>
      {trends.length ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <div style={{ height: 400, textAlign: 'center' }}>
          <Icon type="area-chart" style={{ color: colors.gray4, fontSize: 200, marginTop: 100 }} />
        </div>
      )}
    </div>
  )
}

export default HiringTrendsChart
