/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import isEqual from 'lodash/isEqual'

import usePrevious from '../usePrevious'

export const useOnUpdate = (value, effect, otherDeps = []) => {
  const prevValue = usePrevious(value)

  useEffect(() => {
    if (!isEqual(prevValue, value)) {
      effect()
    }
  }, [value, prevValue, effect, ...otherDeps])
}
