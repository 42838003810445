import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import omit from 'lodash/omit'

import { graduateOutcomesTrendsOptionsQuery } from '../gqlQueries'

export const useGraduateOutcomesTrendsOptions = ({
  topLevelFilters,
  finalTaxonomy,
  universitiesToSubmit,
  clientName,
  trendCategory,
}) => {
  const { data, loading, error } = useQuery(graduateOutcomesTrendsOptionsQuery, {
    skip: !clientName,
    variables: {
      category: trendCategory,
      filters: {
        ...omit(topLevelFilters, 'universities'),
        taxonomy: finalTaxonomy,
        universities: universitiesToSubmit,
      },
    },
  })

  const trendOptions = get(data, 'graduateOutcomesTrendsOptions')
  const docCount = get(trendOptions, 'doc_count')

  return {
    docCount,
    graduateOutcomesTrendOptionsError: error,
    loadingGraduateOutcomesTrendsOptions: loading,
    trendOptions,
  }
}
