import React from 'react'

import { Row, Col, Card, Typography } from 'antd'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import round from 'lodash/round'

import CompanyLogo from '../../../../../components/media/CompanyLogo'
import { pickWithInitialState } from '../../../../../utils/data/utils'
import { EmployeeBreakdownBars } from '../../../GraduateExplorer/components/EmployeeBreakdownBars'
import PillList from '../../../GraduateExplorer/components/PillList'
import SkillsBars from '../../../GraduateOutcomes/components/SkillsBars'
import StatCountUpTE from '../../AuthenticatedLayout/components/ReportElements/StatCountUpTE'

import CandidateTabs from './CandidateTabs'

const CompanyView = props => {
  const companyInfo = get(props, 'companyInfo', {})

  const {
    avgSalary,
    avgTenure,
    companyId,
    companySpecialties,
    description,
    employeeBreakdown,
    keywords,
    name,
    skills,
    skillsDetail,
    topAlumni,
    topRecruiters,
  } = pickWithInitialState(companyInfo, [
    ['avgSalary', 0],
    ['avgTenure', 2],
    ['companyId', ''],
    ['companySpecialties', []],
    ['description', ''],
    ['employeeBreakdown', []],
    ['keywords', []],
    ['name', ''],
    ['skills', []],
    ['skillsDetail', []],
    ['topAlumni', []],
    ['topRecruiters', []],
  ])

  const maxPopularity = get(maxBy(skillsDetail, 'popularity'), 'popularity', 0)
  const maxRelevancy = get(maxBy(skillsDetail, 'relevance'), 'relevance', 0)

  const { Meta } = Card
  const { Paragraph } = Typography

  return (
    <>
      <Meta
        avatar={<CompanyLogo size={60} id={companyId} />}
        title={name}
        description={
          <Paragraph ellipsis={{ expandable: true, rows: 2 }}>
            {description}
          </Paragraph>
        }
      />

      <PillList
        color="orange"
        pills={companySpecialties}
        title="Products/Services"
      />
      <PillList color="blue" pills={skills} title="Skills" />
      <PillList color="green" pills={keywords} title="Keywords" />

      <Row>
        <Meta title="Employee Breakdown" style={{ marginTop: 10 }} />
        <Col span={12}>
          <EmployeeBreakdownBars data={employeeBreakdown.slice(0, 5)} />
        </Col>

        <Col span={12} style={{ marginTop: 10, textAlign: 'center' }}>
          <Col span={24}>
            <StatCountUpTE
              fontSize={40}
              data={avgTenure}
              title="avg. tenure"
              suffix=" years"
              comment={undefined}
              textString={undefined}
              weight={undefined}
            />
          </Col>

          <Col span={24} style={{ marginTop: 30 }}>
            <StatCountUpTE
              fontSize={40}
              data={avgSalary}
              title="avg. salary"
              prefix="$"
              color="green"
              suffix={undefined}
              comment={undefined}
              textString={undefined}
              weight={undefined}
            />
          </Col>
        </Col>
      </Row>

      {topAlumni.length || topRecruiters.length ? (
        <CandidateTabs topAlumni={topAlumni} topRecruiters={topRecruiters} />
      ) : null}

      <Row>
        <Meta title="Top Skills" />
        {skillsDetail.map((skill, index) => (
          <SkillsBars
            key={`${get(skill, 'name')}${index}`}
            name={get(skill, 'name')}
            values={[
              {
                name: 'popularity',
                value: round((get(skill, 'popularity') * 100) / maxPopularity),
              },
              {
                name: 'relevancy',
                // normalize to 70, and add 30 as a minimum
                value:
                  round((get(skill, 'relevance') * 70) / maxRelevancy) + 30,
              },
            ]}
          />
        ))}
      </Row>
    </>
  )
}

export default CompanyView
