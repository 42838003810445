import React from 'react'

import { Layout } from 'antd'
import styled from 'styled-components'

import { getBackgroundColor } from '../../../../components/hocs/withOverriddenTheme'

import AuthenticatedHeader from './components/AuthenticatedHeader'
import PageTitle from './components/PageTitle'

const { Content } = Layout

const SLayout = styled(Layout)`
  min-height: 100px !important;
`

const SContent = styled(Content)`
  background: "${getBackgroundColor}";
  padding-bottom: 280px !important;
`

const AuthenticatedLayout = props => {
  return (
    <SLayout>
      <AuthenticatedHeader />
      <SContent className={props.gutters ? 'gutters' : ''}>
        {props.title && <PageTitle title={props.title} />}
        {props.children}
      </SContent>
    </SLayout>
  )
}

export default AuthenticatedLayout
