import usePrevious from '../../../../../hooks/usePrevious'

/**
 * @description Ignore loading state and just directly transition from previously loaded data to new data.
 * @param {array} data
 * @returns {array}
 */
export const useCurrentlyLoaded = data => {
  const prevData = usePrevious(data)
  const loadedTrends = data && data.length ? data : prevData

  return loadedTrends
}
