import * as FullStory from '@fullstory/browser'

const sendFullStoryUserData = user => {
  const {
    id,
    impersonatorId,
    email,
    firstName,
    lastName,
    clientId,
    clientName,
    createdAt,
    isAdmin,
    isSystemAdmin,
  } = user

  const uid = id
  const displayName = impersonatorId ? `Impersonator as ${email} ` : `${firstName} ${lastName}`

  const userVars = {
    clientId: clientId,
    clientName: clientName,
    createdAt: createdAt,
    displayName,
    email: email,
    impersonatorId: impersonatorId ? impersonatorId : '',
    isAdmin: isAdmin,
    isSystemAdmin: isSystemAdmin,
  }

  FullStory.identify(uid, userVars)
}

export const sendUserData = user => {
  if (user && user !== '') {
    sendFullStoryUserData(user)
  }
}
