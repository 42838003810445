import React from 'react'

import { Spin, Typography } from 'antd'
import noop from 'lodash/noop'
import PT from 'prop-types'

import { pickWithDefaults } from '../../../../../../../utils/data/utils'
import { capitalize } from '../../../../../../../utils/stringManipulation'

const { Text } = Typography

const IndustryOptions = props => {
  const { options, selectedOption, handleIndustryClick, loadingTrendsData } = pickWithDefaults(
    props,
    {
      handleIndustryClick: noop,
      loadingTrendsData: false,
      options: [],
      selectedOption: '',
    },
  )

  return options.map((option, index) => {
    const { key: name, doc_count: docCount } = pickWithDefaults(option, {
      doc_count: 0,
      key: '',
    })

    const isSelected = selectedOption === name
    const backgroundColor = isSelected
      ? 'rgb(80, 153, 222, 0.5)'
      : !option && index === 0
      ? 'rgb(80, 153, 222, 0.5)'
      : ''

    const nameDisplay =
      isSelected && loadingTrendsData ? (
        <div style={{ height: 20, marginLeft: 8, opacity: 0.8 }}>
          <Spin size="small" />
        </div>
      ) : (
        <div style={{ float: 'left' }}>
          <Text>{capitalize(name)}</Text>
        </div>
      )

    const handleSelect = name => {
      if (loadingTrendsData) {
        return
      } else {
        handleIndustryClick(name)
      }
    }

    return (
      <div
        key={`${name}${docCount}`}
        className="click-list-item"
        onClick={() => handleSelect(name)}
        style={{
          backgroundColor,
          clear: 'both',
        }}
      >
        <div>{nameDisplay}</div>
      </div>
    )
  })
}

IndustryOptions.propTypes = {
  handleIndustryClick: PT.func.isRequired,
  loadingTrendsData: PT.bool,
  options: PT.arrayOf(PT.string).isRequired,
  selectedOption: PT.string.isRequired,
}

export default IndustryOptions
