import React from 'react'

import { Row, Col, Typography } from 'antd'
import toUpper from 'lodash/toUpper'
import PT from 'prop-types'
import CountUp from 'react-countup'

import { default as nc } from '../../../../../../utils/numberConverter'
import UniversityLogo from '../../../../Dashboard/components/UniversityLogo'

const { Paragraph } = Typography

const ReportHeader = ({
  universityNameDisplay,
  title,
  url,
  description,
  recordCount,
  totalRecords,
  videoLink,
}) => (
  <Row className="report-header" style={{ paddingTop: 30 }}>
    <Col span={16}>
      <Paragraph ellipsis className="sub-header sh-blue">
        {toUpper(universityNameDisplay)}
      </Paragraph>
      <UniversityLogo url={url} additionalStyles={{ marginTop: 10 }} margin={80}>
        <h1 style={{ display: 'inline' }}>{title}</h1>
        {videoLink}
        <p>{description}</p>
      </UniversityLogo>
    </Col>
    <Col span={8} style={{ marginTop: 20, textAlign: 'right' }}>
      {/* TODO - Investigate issues with Print Styles in varous environments */}
      {/* {title.includes('Outcomes') && isChrome && (
        <Tooltip title="Print this Page" placement="left">
          <Button
            type="primary"
            icon="printer"
            onClick={() => window.print()}
            style={{ marginRight: 15 }}
            className="print-button"
            shape="circle"
            ghost
          />
        </Tooltip>
      )} */}
      <div style={{ float: 'right', minWidth: 140 }}>
        <b>
          <CountUp separator="," start={0} end={recordCount} />{' '}
        </b>
        of {nc(totalRecords)}
        <div className="sub-header sh-orange">Profiles</div>
      </div>
    </Col>
  </Row>
)

ReportHeader.propTypes = {
  description: PT.string.isRequired,
  recordCount: PT.number.isRequired,

  // University of Georgia
  title: PT.string.isRequired,

  totalRecords: PT.number.isRequired,
  universityNameDisplay: PT.string.isRequired,
  url: PT.string,
  videoLink: PT.node,
}

export default ReportHeader
