import React from 'react'

import PT from 'prop-types'

const PageTitle = props => {
  return (
    <div className="max-1200" style={{ marginBottom: 40, marginTop: 20 }}>
      <h2>{props.title}</h2>
    </div>
  )
}

PageTitle.propTypes = {
  title: PT.string.isRequired,
}

export default PageTitle
