import { sha256 } from 'js-sha256'

let hmac = null

const createNewHMAC = userId => {
  hmac = userId ? sha256.hmac(process.env.REACT_APP_INTERCOM_SECRET_KEY, userId) : null
}

const intercomBoot = userData => {
  if (!hmac) createNewHMAC(userData.user_id)
  window.Intercom('boot', { ...userData, user_hash: hmac })
}

const intercomUpdate = userData => {
  if (!hmac) createNewHMAC(userData.user_id)
  window.Intercom('update', { ...userData, user_hash: hmac })
}

const intercomEvent = (eventName, metaData) => {
  window.Intercom('trackEvent', eventName, { ...metaData, user_hash: hmac })
}

const intercomEndSession = () => window.Intercom('shutdown')

const intercomStartFreshSession = () =>
  window.Intercom('boot', { app_id: process.env.REACT_APP_INTERCOM_ID })

export default {
  createNewHMAC,
  intercomBoot,
  intercomEndSession,
  intercomEvent,
  intercomStartFreshSession,
  intercomUpdate,
}
