import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { UNAUTHENTICATED_ROUTES } from '../../utils/routing/routes'
import { loadWithDelay } from '../../utils/routing/routes/load'
import withAsyncLoading from '../../components/hocs/withAsyncLoading'
import { useProtectUnauthenticatedRoutes } from '../../utils/authentication'

const Authenticate = React.lazy(loadWithDelay(import('./Authenticate'), 500))
const ForgotPassword = React.lazy(
  loadWithDelay(import('./ForgotPassword'), 500)
)

const Register = React.lazy(loadWithDelay(import('./Register'), 500))
const ResetPassword = React.lazy(loadWithDelay(import('./ResetPassword'), 500))

const Unauthenticated = props => {
  useProtectUnauthenticatedRoutes(props.history)
  return (
    <Switch>
      <Route
        path={UNAUTHENTICATED_ROUTES.AUTHENTICATE}
        component={withAsyncLoading(Authenticate)}
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.FORGOT_PASSWORD}
        component={withAsyncLoading(ForgotPassword)}
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.RESET_PASSWORD}
        component={withAsyncLoading(ResetPassword)}
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.REGISTER}
        component={withAsyncLoading(Register)}
      />
      <Route component={withAsyncLoading(Authenticate)} />
    </Switch>
  )
}

export default Unauthenticated
