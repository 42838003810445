import React from 'react'
import { Form, Table } from 'antd'
import styled from 'styled-components'

import { getHelp, getValidateStatus } from '../../../utils/forms'

const Container = styled.div``

const FormTableInput = props => {
  const { input, label, meta, ...rest } = props

  return (
    <Container>
      <Form.Item
        hasFeedback
        help={getHelp(meta)}
        label={label}
        validateStatus={getValidateStatus(meta)}
      >
        <Table {...rest} />
      </Form.Item>
    </Container>
  )
}

FormTableInput.propTypes = {}

export default FormTableInput
