import gql from 'graphql-tag'

export const universityClientUserProfileQuery = gql`
  query UniversityClientUserProfile {
    universityClientUserProfile {
      clientName
      haveIncludeUniversityEmployees
      id
      multiSchoolDims
      taxonomy
      url
    }
  }
`

export const graduateOutcomesOverviewQuery = gql`
  query GraduateOutcomesOverview($filters: JSON!) {
    graduateOutcomesOverview(filters: $filters)
  }
`

export const graduateOutcomesTrendsDataQuery = gql`
  query GraduateOutcomesTrendsData(
    $filters: JSON!
    $category: TrendCategories!
    $targets: [String]!
  ) {
    graduateOutcomesTrendsData(filters: $filters, category: $category, targets: $targets) {
      __typename
      ... on GraduateOutcomesIndustryTrendsData {
        target_industry
        industry_hiring_trends {
          year
          total_hires_volume
          total_hires_pct
        }
      }
      ... on GraduateOutcomesJobTrendsData {
        target_job_function
        job_functions_hiring_trends {
          year
          total_hires_volume
          total_hires_pct
        }
      }
      ... on GraduateOutcomesCompanyTrendsData {
        doc_count
        companyHiringTrends {
          name
          doc_count
          trends {
            year
            y
            alumni
          }
          volume
          trendSlope
        }
      }
    }
  }
`

export const graduateOutcomesTrendsOptionsQuery = gql`
  query GraduateOutcomesTrendsOptions($filters: JSON!, $category: TrendCategories!) {
    graduateOutcomesTrendsOptions(filters: $filters, category: $category) {
      __typename
      ... on GraduateOutcomesIndustryOptionsData {
        doc_count
        top_industries {
          doc_count
          key
        }
      }
      ... on GraduateOutcomesJobCategoryOptionsData {
        doc_count
        top_job_categories {
          doc_count
          key
        }
      }
      ... on GraduateOutcomesCompanyOptionsData {
        doc_count
        topCompanies {
          name
          doc_count
          company_id
          url
          skills
        }
      }
    }
  }
`

export const graduateOutcomesCompanyDetailsQuery = gql`
  query GraduateOutcomesCompanyDetails($filters: JSON!) {
    graduateOutcomesCompanyDetails(filters: $filters)
  }
`

export const graduateOutcomesSkillsQuery = gql`
  query GraduateOutcomesSkills($filters: JSON!) {
    graduateOutcomesSkills(filters: $filters)
  }
`

export const skillDescriptionQuery = gql`
  query SkillDescription($skillName: String!) {
    skillDescription(skillName: $skillName)
  }
`

export const getTotalCountForUniversityQuery = gql`
  query GetTotalCountForUniversity($filters: JSON) {
    getTotalCountForUniversity(filters: $filters)
  }
`
