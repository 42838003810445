import React from 'react'

import { Card, Col, Row, Typography, Skeleton } from 'antd'
import get from 'lodash/get'
import head from 'lodash/head'

import HeatMapChart from '../../../../../components/charts/HeatMapChart'
import HeatMapLegend from '../../../../../components/charts/HeatMapLegend'
import MapBubbleLegend from '../../../../../components/charts/MapBubbleLegend'
import SpinnerWithUberFacts from '../../../../../components/layout/SpinnerWithUberFacts'
import { IMAGES } from '../../../../../utils/applicationConstants'
import { pickWithInitialState } from '../../../../../utils/data/utils'
import { capitalize } from '../../../../../utils/stringManipulation'
import ClickFilters from '../../../components/AuthenticatedLayout/components/ReportElements/ClickFilter'
import OverviewStats from '../../components/OverviewStats'

const { Meta } = Card
const { Text } = Typography

const CLICK_FILTER_KEYS = {
  currentEmployers: 'c_companies',
  jobTitles: 'c_job_titles',
  majors: 'c_majors',
  states: 'c_states',
}

const CLICK_FILTER_CATEGORIES = [
  {
    description: 'Top education majors of your alumni',
    key: CLICK_FILTER_KEYS.majors,
    title: 'Majors',
  },
  {
    description: 'Top companies of your alumni',
    key: CLICK_FILTER_KEYS.currentEmployers,
    title: 'Employers',
  },
  {
    description: 'Most frequent job titles of your alumni',
    key: CLICK_FILTER_KEYS.jobTitles,
    title: 'Job Titles',
  },
]

const Overview = ({
  clearClickFilter,
  overviewData,
  overviewClickFilters,
  loading,
  handleGeoSelection,
  handleClickFilterClick,
  clickFilterOptions,
}) => {
  const { avgAge, avgSalary, geoData, pctMale, total } = pickWithInitialState(overviewData, [
    ['avgAge', 0],
    ['avgSalary', 0],
    ['clickFacets', {}],
    ['geoData', {}],
    ['pctMale', 0],
    ['total', 0],
  ])

  const clickFilterData = pickWithInitialState(clickFilterOptions, [
    ['c_companies', []],
    ['c_job_titles', []],
    ['c_majors', []],
    ['c_states', []],
  ])

  /**
   *
   * @param {string} key
   * @returns {Array} Click Filter DAta
   */
  const getClickFilterDataForCategory = key => get(clickFilterData, key, [])

  /**
   *
   * @param {string} key
   * @returns {string} Click Filter That is Applied
   */
  const getSelectedClickFilterForCategory = key => head(get(overviewClickFilters, key, []))

  const topState = getSelectedClickFilterForCategory(CLICK_FILTER_KEYS.states)

  return (
    <SpinnerWithUberFacts loading={loading}>
      <br />
      <Card>
        <OverviewStats
          avgAge={avgAge}
          avgSalary={avgSalary}
          pctMale={pctMale}
          total={total}
          topTotal={total}
        />
      </Card>

      <Card style={{ marginTop: 20 }}>
        <Row gutter={16} type="flex">
          <Col md={8}>
            <Meta title="Top States" description="The top states where your alumni live and work" />
            <Skeleton loading={loading} paragraph={{ rows: 8 }}>
              <div className="click-list" style={{ minHeight: 300 }}>
                {getClickFilterDataForCategory(CLICK_FILTER_KEYS.states).map((state, index) => (
                  <div
                    key={`${state}${index}`}
                    className="click-list-item"
                    onClick={() => {
                      handleClickFilterClick(CLICK_FILTER_KEYS.states, get(state, 'key'))
                    }}
                    style={{
                      backgroundColor:
                        topState === get(state, 'key') ? 'rgb(80, 153, 222, 0.5)' : '',
                    }}
                  >
                    <div>
                      <div style={{ float: 'left' }}>
                        <Text>{capitalize(get(state, 'key', ''))}</Text>
                      </div>
                      <div style={{ float: 'right' }}>
                        <Text strong>{`${get(state, 'doc_count', 0)}%`}</Text>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Skeleton>
            <MapBubbleLegend />
            <HeatMapLegend />
          </Col>
          <Col md={16} style={{ minHeight: 500 }}>
            <Meta
              title="Alumni Geography"
              description="Alumni counts displayed with bubbles, and local population shown with variable color on state and county levels."
            />
            <div>
              <div
                style={{
                  borderRadius: 4,
                  height: 460,
                  minHeight: 100,
                  overflow: 'hidden',
                }}
              >
                {!loading ? (
                  <HeatMapChart
                    data={geoData}
                    handleSelection={handleGeoSelection}
                    height={490} //difference to hide mapbox logos and content
                    filtersApplied={
                      !!get(overviewClickFilters, 'geoPoints') &&
                      !!get(overviewClickFilters, 'geoPoints').length
                    }
                    mapSelectionEnabled
                  />
                ) : (
                  <div>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_BUCKET}${IMAGES.blankMap}`}
                      alt="Blank Map"
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          {CLICK_FILTER_CATEGORIES.map(category => (
            <Col key={category.key} span={8}>
              <ClickFilters
                key={category.key}
                data={getClickFilterDataForCategory(category.key)}
                onBarClick={value => handleClickFilterClick(category.key, value)}
                selectedValue={getSelectedClickFilterForCategory(category.key)}
                title={category.title}
                description={category.description}
                onClearFilter={() => clearClickFilter(category.key)}
              />
            </Col>
          ))}
        </Row>
      </Card>
    </SpinnerWithUberFacts>
  )
}

export default Overview
