import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Checkbox, Row } from 'antd'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import lt from 'lodash/lt'
import toString from 'lodash/toString'
import PT from 'prop-types'
import styled from 'styled-components'

import { hasLength, extractKeysFromList } from '../../../../../utils/data/utils'
import { capitalize } from '../../../../../utils/stringManipulation'

const Container = styled.div`
  overflow-y: auto;
  max-height: 500px;
`

const CheckboxList = props => {
  const { metric, onChange, options, selectedFilters, setDisabled, disabled } = props

  /**
   * Selectable options (names) of the facet values
   */
  const selectableOptions = useMemo(
    () =>
      metric === 'grad_years'
        ? extractKeysFromList(options).sort((a, b) => b - a)
        : extractKeysFromList(options).sort(),
    [options, metric],
  )

  /**
   * Checkbox states
   */
  const [checkAll, setCheckAll] = useState(true)
  const [checkedItems, setCheckedItems] = useState(selectedFilters)
  const [indeterminate, setIndeterminate] = useState(false)

  /**
   * When an individual radio button is clicked
   */
  const onClickOption = useCallback(
    checkedList => {
      metric === 'universities' && !checkedList.length ? setDisabled(true) : setDisabled(false)
      setCheckAll(isEqual(checkedItems.length, selectableOptions.length))
      setCheckedItems(checkedList)
      setIndeterminate(hasLength(checkedList) && lt(checkedList.length, selectableOptions.length))
    },
    [checkedItems, selectableOptions, metric, setDisabled],
  )

  /**
   * When the Select All button is changed
   */
  const onCheckAllChange = useCallback(
    e => {
      const nextCheckAll = get(e, 'target.checked')
      metric === 'universities' && !nextCheckAll ? setDisabled(true) : setDisabled(false)
      setCheckAll(nextCheckAll)
      setCheckedItems(nextCheckAll ? selectableOptions : [])
      setIndeterminate(hasLength(checkedItems) && lt(selectableOptions.length, checkedItems.length))
    },
    // eslint-disable-next-line
    [checkAll, checkedItems, selectableOptions],
  )

  /**
   * When the value changes, run the onChange func prop
   */
  useEffect(() => {
    onChange(checkedItems)
    // eslint-disable-next-line
  }, [checkedItems])

  return (
    <Container>
      <Row
        style={{
          borderBottom: '0.5px solid',
          marginBottom: 10,
          paddingBottom: 2,
        }}
      >
        <Checkbox defaultChecked={false} indeterminate={indeterminate} onChange={onCheckAllChange}>
          Select All
        </Checkbox>
      </Row>
      <Row>
        <Checkbox.Group value={checkedItems} onChange={onClickOption} className="block-labels">
          {selectableOptions.map((option, index) => (
            <Checkbox key={`${option}${index}`} value={option} style={{ marginLeft: 0 }}>
              {capitalize(toString(option))}
            </Checkbox>
          ))}
        </Checkbox.Group>
        {disabled && metric === 'universities' ? (
          <span style={{ color: '#ee6352' }}>Cannot submit with no {metric} selected.</span>
        ) : null}
      </Row>
    </Container>
  )
}

const OptionPT = PT.shape({
  docCount: PT.number,
  id: PT.string,
})

CheckboxList.propTypes = {
  options: PT.arrayOf(OptionPT).isRequired,
}

export default CheckboxList
