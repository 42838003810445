import { stringSeparator } from '../stringManipulation'

const DEMO_DIM_NAMES = 'demoDimNames'
const DEMO_SAVED_SEARCH_NAME = 'demoSavedSearchName'
const USER_VIEW_EMAIL = 'userViewEmail'
const TAXONOMY = 'taxonomy'
const DEMO_URL = 'demoUrl'

export const clearDemoDimNames = () => window['localStorage'].removeItem(DEMO_DIM_NAMES)

export const getDemoDimNames = () => {
  const demoDimNames = window['localStorage'].getItem(DEMO_DIM_NAMES)
  return demoDimNames ? demoDimNames.split(stringSeparator) : []
}

export const setDemoDimNames = demoDimNames =>
  window['localStorage'].setItem(DEMO_DIM_NAMES, demoDimNames.join(stringSeparator))

export const clearDemoSavedSearchName = () =>
  window['localStorage'].removeItem(DEMO_SAVED_SEARCH_NAME)

export const getDemoSavedSearchName = () =>
  window['localStorage'].getItem(DEMO_SAVED_SEARCH_NAME) || null

export const setDemoSavedSearchName = demoSavedSearchName =>
  window['localStorage'].setItem(DEMO_SAVED_SEARCH_NAME, demoSavedSearchName)

export const clearUserViewEmail = () => window['localStorage'].removeItem(USER_VIEW_EMAIL)

export const getUserViewEmail = () => window['localStorage'].getItem(USER_VIEW_EMAIL) || null

export const setUserViewEmail = userViewEmail =>
  window['localStorage'].setItem(USER_VIEW_EMAIL, userViewEmail)

export const clearTaxonomy = () => window['localStorage'].removeItem(TAXONOMY)

export const getTaxonomy = () => window['localStorage'].getItem(TAXONOMY) || null

export const setTaxonomy = taxonomy => window['localStorage'].setItem(TAXONOMY, taxonomy)

export const clearDemoUrl = () => window['localStorage'].removeItem(DEMO_URL)

export const getDemoUrl = () => window['localStorage'].getItem(DEMO_URL) || null

export const setDemoUrl = demoUrl => window['localStorage'].setItem(DEMO_URL, demoUrl)
