import React from 'react'
import PT from 'prop-types'
import { Form, Radio } from 'antd'
import get from 'lodash/get'
import identity from 'lodash/identity'
import { getHelp, getValidateStatus } from '../../../utils/forms'

const FormRadioGroupInput = props => {
  const {
    input,
    label,
    meta,
    options,
    displayMappingFn,
    normalizeDataFn,
    additionalStyles
  } = props
  const { onChange, value } = input

  const antdOptions = displayMappingFn(options)

  const onChangeFn = event => {
    const selectedValue = get(event, 'target.value', null)

    if (selectedValue === 'No Access') onChange()
    else {
      const normalizedValue = normalizeDataFn([selectedValue])
      onChange(normalizedValue)
    }
  }

  const antdValues = displayMappingFn(value ? [value] : []).join('')

  return (
    <div style={{ marginLeft: 10, marginBottom: 10, fontWeight: 'bold' }}>
      <Form.Item
        hasFeedback
        help={getHelp(meta)}
        label={label}
        validateStatus={getValidateStatus(meta)}
        style={{
          ...{
            marginBottom: -10
          },
          ...additionalStyles
        }}
      />
      <div style={{ marginLeft: 15, fontWeight: 'normal' }}>
        <Radio.Group
          options={[...antdOptions, 'No Access']}
          onChange={onChangeFn}
          value={antdValues && antdValues !== '' ? antdValues : 'No Access'}
        />
      </div>
    </div>
  )
}

FormRadioGroupInput.propTypes = {
  displayMappingFn: PT.func,
  normalizeDataFn: PT.func
}

FormRadioGroupInput.defaultProps = {
  displayMappingFn: identity,
  normalizeDataFn: identity
}

export default FormRadioGroupInput
