import gql from 'graphql-tag'

/**
 * Query definition for getting the necessary universityClient fields
 */
export const univeristyClientMembersQuery = gql`
  query UniversityClientMembers {
    universityClient {
      id
      name
      admins {
        id
        fullName
        email
        isAdmin
      }
      availableSeats
      invitations {
        id
        token
        createdAt(format: "lll")
        email
        isAdmin
      }
      members {
        accessDenied
        id
        fullName
        email
        isAdmin
      }
      teams {
        id
        key: id
        name
      }
    }
  }
`

export const universityClientUserProfileQuery = gql`
  query UniversityClientUserProfile {
    universityClientUserProfile {
      id
      isTEClient
    }
  }
`

export const addMoreSeatsForTEMutation = gql`
  mutation AddMoreSeatsForTE($noOfUsers: Int!) {
    addMoreSeatsForTE(noOfUsers: $noOfUsers)
  }
`
