import React from 'react'

const ErrorPage = props => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Something went wrong...</h1>
    </div>
  )
}

export default ErrorPage
