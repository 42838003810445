import React, { useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Button, Dropdown, Icon, Layout, Menu, Popconfirm } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import brainLogo from '../../../../../../assets/logos/brain-logo.svg'
import { getWhiteColor } from '../../../../../../components/hocs/withOverriddenTheme'
import errorService from '../../../../../../utils/analytics/error'
import { logout } from '../../../../../../utils/authentication'
import { pickWithInitialState, hasLength } from '../../../../../../utils/data/utils'
import { useSendUserData } from '../../../../../../utils/fullstory/hooks/useSendUserData'
import { AUTHENTICATED_ROUTES } from '../../../../../../utils/routing/routes'
import { getDemoDimNames } from '../../../../../../utils/superUserDataInteraction'

import HeaderLinkButton from './components/HeaderLinkButton'

const { Header } = Layout

const SButton = styled(Button)`
  margin: 0px 8px 0px 8px;
`

const SHeader = styled(Header)`
  background-color: ${getWhiteColor} !important;
  border-bottom: 3px solid #eee;
`

const LogoContainer = styled.div`
  cursor: pointer;
`

const MenuItemsContainer = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`

const DropdownMenuItemsContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`

const SImg = styled.img`
  max-width: 200px;
`

export const universityClientUserProfileQuery = gql`
  query UniversityClientUserProfile {
    universityClientUserProfile {
      documents {
        id
        name
      }
      email
      firstName
      id
      impersonatorId
      isAdmin
      isSystemAdmin
      lastName
      clientName
      clientId
      createdAt
    }
  }
`

const getDataFields = (data = {}) => {
  const universityClientUserProfile = _.get(data, 'universityClientUserProfile')
  return pickWithInitialState(universityClientUserProfile, [
    ['email', ''],
    ['documents', []],
    ['isAdmin', false],
    ['isSystemAdmin', false],
    ['id', 'No User Id Available'],
  ])
}

const AuthenticatedHeader = props => {
  const navigate = useCallback(
    route => {
      props.history.push(route)
    },
    [props.history],
  )

  const onLogout = useCallback(() => {
    logout()
  }, [])

  const demoDimNames = getDemoDimNames()

  const { data } = useQuery(universityClientUserProfileQuery)

  const { documents, isAdmin, isSystemAdmin, id, email } = getDataFields(data)

  const hasDocuments = hasLength(documents)
  errorService.setUserContext({ email: email, id: id })

  useSendUserData(data)

  return (
    <SHeader style={{ padding: 0 }}>
      <div className="header-container">
        <div className="left-nav-wrapper">
          <LogoContainer
            role="button"
            onClick={() => navigate(AUTHENTICATED_ROUTES.DASHBOARD)}
            className="brand-logo"
          >
            <SImg alt="Steppingblocks Logo" src={brainLogo} />
          </LogoContainer>
          <MenuItemsContainer>
            <HeaderLinkButton route={AUTHENTICATED_ROUTES.DASHBOARD}>
              <Icon type="database" style={{ fontSize: 20 }} />
              Dashboard
            </HeaderLinkButton>
            {hasDocuments && (
              <HeaderLinkButton route={AUTHENTICATED_ROUTES.DATA_FILES}>
                <Icon type="database" style={{ fontSize: 20 }} />
                Data
              </HeaderLinkButton>
            )}
            {isAdmin && (
              <HeaderLinkButton route={AUTHENTICATED_ROUTES.MANAGE_TEAM}>
                <Icon type="team" style={{ fontSize: 20 }} />
                Team
              </HeaderLinkButton>
            )}
            {isSystemAdmin && (
              <span>
                <HeaderLinkButton route={AUTHENTICATED_ROUTES.DEMO}>
                  <Icon
                    type="project"
                    theme={demoDimNames && !!demoDimNames.length ? 'twoTone' : 'outlined'}
                    twoToneColor="#eb2f96"
                    style={{ fontSize: 20 }}
                  />
                  Demo
                </HeaderLinkButton>
              </span>
            )}
          </MenuItemsContainer>
        </div>
        <Query query={universityClientUserProfileQuery}>
          {({ data }) => {
            const { isAdmin } = getDataFields(data)

            const buttons = [
              {
                icon: 'user',
                route: AUTHENTICATED_ROUTES.MY_ACCOUNT,
                title: 'My Account',
              },
              {
                icon: 'question-circle',
                route: AUTHENTICATED_ROUTES.HELP,
                title: 'Help',
              },
            ]
            return (
              <React.Fragment>
                <MenuItemsContainer>
                  {buttons.map(({ title, icon, ...rest }) => (
                    <HeaderLinkButton key={title} {...rest}>
                      <Icon type={icon} />
                      {title}
                    </HeaderLinkButton>
                  ))}
                  <Popconfirm
                    onConfirm={onLogout}
                    title="Are you sure you want to log out?"
                    okText="Yes"
                    placement="rightBottom"
                  >
                    <SButton
                      type="link"
                      // onClick={onLogout}
                      style={{ height: 61 }}
                    >
                      <Icon type="upload" style={{ fontSize: 20, transform: 'rotate(90deg)' }} />
                    </SButton>
                  </Popconfirm>
                </MenuItemsContainer>
                <DropdownMenuItemsContainer>
                  <Dropdown
                    overlay={
                      <Menu onClick={({ key }) => navigate(key)}>
                        {/* Removed tableau links from mobile */}
                        {/* {_.uniqBy(reports, 'name').map(({ id, name }) => (
                        <Menu.Item key={getRouteFromName(name)}>
                          {name}
                        </Menu.Item>
                      ))}
                      {hasDocuments && (
                        <Menu.Item key={AUTHENTICATED_ROUTES.DATA_FILES}>
                          Data
                        </Menu.Item>
                      )} */}
                        {isAdmin && (
                          <Menu.Item key={AUTHENTICATED_ROUTES.MANAGE_TEAM}>Manage Team</Menu.Item>
                        )}
                        {buttons.map(({ route, title }) => (
                          <Menu.Item key={route}>{title}</Menu.Item>
                        ))}
                        <Menu.Divider />
                        <Menu.Item key="logout" onClick={onLogout}>
                          Log Out
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <SButton icon="menu" />
                  </Dropdown>
                </DropdownMenuItemsContainer>
              </React.Fragment>
            )
          }}
        </Query>
      </div>
    </SHeader>
  )
}

export default withRouter(AuthenticatedHeader)
