import React from 'react'

import { Card, Row } from 'antd'
import PT from 'prop-types'

function TrendsCard(props) {
  const { children } = props
  return (
    <Card style={{ marginTop: 30 }}>
      <Row style={{ marginBottom: 0 }}>{children}</Row>
    </Card>
  )
}

TrendsCard.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
}

export default TrendsCard
