import { useState } from 'react'

import get from 'lodash/get'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import omit from 'lodash/omit'

import sendFullStoryEvent from '../../utils/analytics/sendFullStoryEvent'
import fullstory from '../../utils/constants/fullstory'
import { getSelectedClickFilter } from '../../utils/stringManipulation'

const useGogeFilters = ({ grandTotal, topLevelFilters, setTopLevelFilters }) => {
  const [overviewClickFilters, setOverviewClickFilters] = useState({
    geoPoints: [],
  })
  const [recordCount, setRecordCount] = useState(0)

  const reportClick = ({ key, value }) => {
    const eventName = `Filter by ${getSelectedClickFilter(key)}`
    const getGeoValue = value => {
      if (!value || !value.length) {
        return 'Clear Map Selection'
      } else return 'Map Selection'
    }
    const eventValue = key === 'geoPoints' ? getGeoValue(value) : value
    const eventProperties = {
      value_str: eventValue,
    }
    sendFullStoryEvent(eventName, eventProperties)
  }

  const checkTopLevelFiltersExists = filters => {
    let filtersExist = false
    keys(filters).forEach(filter => {
      if (filter === 'include_university_employees' && !!filters[filter]) filtersExist = true
      else if (filters[filter] && !!filters[filter].length) filtersExist = true
    })
    return filtersExist
  }

  const handleClickFilterClick = (key, value) => {
    // remove key from filters if already applied
    if (includes(get(overviewClickFilters, key, []), value)) {
      setOverviewClickFilters(omit(overviewClickFilters, key))
    } else {
      reportClick({ key, value })
      // add key to filters
      if (overviewClickFilters) {
        setOverviewClickFilters({
          ...overviewClickFilters,
          [key]: [value],
        })
      }
    }
  }

  const clearClickFilter = key => {
    const eventProperties = {
      filter_str: getSelectedClickFilter(key),
    }
    sendFullStoryEvent(fullstory.eventNames.clearClickFilter, eventProperties)
    setOverviewClickFilters(omit(overviewClickFilters, key))
  }

  const handleGeoSelection = points => {
    reportClick({ key: 'geoPoints', value: points })
    setOverviewClickFilters({
      ...overviewClickFilters,
      geoPoints: points,
    })
  }

  const onChangeCustomFilters = (filterValues = {}) => {
    const newTopLevelFilters = {
      ...topLevelFilters,
      ...omit(filterValues, keys(overviewClickFilters)),
    }

    setTopLevelFilters(newTopLevelFilters)
    setOverviewClickFilters({})
    if (!checkTopLevelFiltersExists(newTopLevelFilters)) setRecordCount(grandTotal)
  }

  const clearIndividualFilter = metric => {
    const newTopLevelFilters = omit(topLevelFilters, metric)

    setTopLevelFilters(newTopLevelFilters)
    setOverviewClickFilters({})
    if (!checkTopLevelFiltersExists(newTopLevelFilters)) setRecordCount(grandTotal)
  }

  const clearTopLevelFilters = () => {
    sendFullStoryEvent(fullstory.eventNames.clearTopLevelFilters, {})
    setTopLevelFilters({})
    setOverviewClickFilters({})
    setRecordCount(grandTotal)
  }

  return {
    checkTopLevelFiltersExists,
    clearClickFilter,
    clearIndividualFilter,
    clearTopLevelFilters,
    handleClickFilterClick,
    handleGeoSelection,
    onChangeCustomFilters,
    overviewClickFilters,
    recordCount,
    setRecordCount,
  }
}

export default useGogeFilters
