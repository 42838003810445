import React, { Component } from 'react'

import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { withTheme } from 'components'
import compose from 'lodash/fp/compose'

import ErrorPage from './components/errorBoundary/components/ErrorPage'
import withGraphQL from './components/hocs/withGraphQL'
import withOverriddenTheme from './components/hocs/withOverriddenTheme'
import Routes from './routes'
import errorService from './utils/analytics/error'

FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORGID })

class App extends Component {
  componentDidMount() {
    errorService.configure()
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={() => <ErrorPage />}>
        <Routes />
      </Sentry.ErrorBoundary>
    )
  }
}

const withEnhancers = compose(withGraphQL, withTheme, withOverriddenTheme)

export default Sentry.withProfiler(withEnhancers(App), { name: 'GOGE' })
