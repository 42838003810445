import React from 'react'
import { Field } from 'react-final-form'
import FormTableInput from '../../../../../../../../../components/forms/FormTableInput'
import get from 'lodash/get'
import ReassignTeamSelect from './components/ReassignTeamSelect'

const CurrentUsersTab = props => {
  const dataSource = get(props, 'dataSource')
  const handleRemoveMemberClick = get(props, 'handleRemoveMemberClick')
  const reassignTeamMember = get(props, 'reassignTeamMember')
  const teams = get(props, 'teams')

  return (
    <Field
      name="members"
      label="Team Members"
      component={FormTableInput}
      columns={[
        {
          title: 'Name',
          dataIndex: 'firstName',
          render: (text, { lastName, warning }) => (
            <div>{`${text} ${lastName}`}</div>
          )
        },
        { title: 'Email', dataIndex: 'email' },
        {
          align: 'right',
          title: '',
          dataIndex: 'action',
          render: (text, record) => (
            <ReassignTeamSelect
              member={record}
              teams={teams}
              handleRemoveMemberClick={handleRemoveMemberClick}
              reassignTeamMember={reassignTeamMember}
            />
          )
        }
      ]}
      dataSource={dataSource}
      pagination={false}
      scroll={{ y: 240 }}
    />
  )
}

export default CurrentUsersTab
