import React, { useMemo } from 'react'

import { Form, Input, Typography } from 'antd'
import _ from 'lodash'
import _fp from 'lodash/fp'
import PT from 'prop-types'
import styled from 'styled-components'

import CustomButton from '../../../../../../../../../../components/forms/CustomButton'
import InputWithArrayValue from '../../../../../../../../../../components/forms/InputWithArrayValue/index'
import SwitchInput from '../../../../../../../../../../components/forms/SwitchInput/index'
import TableSelectorInput from '../../../../../../../../../../components/forms/TableSelectorInput/index'
import fullstory from '../../../../../../../../../../utils/constants/fullstory'

/**
 * Themable Form.Item
 */
const SFormItem = Form.Item

/**
 * Label
 */
const FormSectionLabel = styled(Typography.Text)`
  font-size: 1.1rem;
  font-weight: 800;
`

/**
 * Creates a form section that is really just a label
 * @param {String} label
 * @param {Object} overrides
 */
const createLabelSection = (label, overrides = {}) => ({
  Component: FormSectionLabel,
  formItemProps: {
    style: { margin: 0 },
  },
  inputProps: {
    children: label,
  },
  name: label,
  ...overrides,
})

const getFormFields = props => {
  const getEmailHelp = _.get(props, 'getEmailHelp')
  const isFieldsTouched = _.get(props, 'form.isFieldsTouched')
  const adminSelected = _.get(props, 'form.getFieldValue')('isAdmin')
  return [
    {
      Component: InputWithArrayValue,
      fieldConfig: {
        rules: [
          {
            message: 'Email required',
            required: true,
            type: 'array',
            whitespace: true,
          },
        ],
      },
      formItemProps: { label: 'Invite by Email' },
      getHelp: values => {
        const emails = _.get(values, 'email')
        return isFieldsTouched() && getEmailHelp(emails)
      },
      inputProps: {
        autoFocus: true,
        className: fullstory.classNames.textInput,
        ref: React.createRef(),
      },
      name: 'email',
    },
    {
      Component: Input.TextArea,
      formItemProps: { label: 'Personalized Message (optional)' },
      inputProps: {
        className: fullstory.classNames.textAreaInput,
        placeholder:
          'Provide optional personalized message here. If not provided we will send an email with generic message.',
        ref: React.createRef(),
      },
      name: 'message',
    },
    {
      Component: SwitchInput,
      formItemProps: { label: 'Provide Administrative access?' },
      name: 'isAdmin',
    },
    createLabelSection('Select Teams'),
    {
      Component: TableSelectorInput,
      fieldConfig: {
        rules: [
          {
            validator: (rule, value, callback) => {
              if (!adminSelected && value.length < 1) {
                return callback('Users must be assigned to at least one team')
              } else {
                return callback()
              }
            },
          },
        ],
      },
      formItemProps: { style: { marginTop: 0 } },
      inputProps: {
        columns: [{ dataIndex: 'name', title: 'Team Name' }],
        data: _.get(props, 'teams'),
        disabled: adminSelected,
        pagination: false,
        ref: React.createRef(),
        scroll: { y: 240 },
      },
      name: 'teams',
    },
  ]
}

const MemberInviteForm = props => {
  const {
    formProps,
    submitButtonContent,
    submitButtonHidden,
    submitButtonProps,
    submitForm,
    validate,
  } = props
  const { getFieldDecorator, getFieldsValue, setFields } = props.form

  const { email, teams } = props.form.getFieldsValue()
  const isAdmin = _.get(props, 'form.getFieldValue')('isAdmin')
  const errors = validate({
    email,
    isAdmin,
    teams,
  })
  const hasErrors = errors.email || errors.teams

  const fields = useMemo(() => getFormFields(props), [props])
  return (
    <Form
      hideRequiredMark={true}
      {...formProps}
      onSubmit={e => {
        e.preventDefault()
        submitForm(getFieldsValue())
      }}
    >
      {fields.map(({ Component, formItemProps, inputProps, name, fieldConfig, getHelp }) => {
        return (
          <SFormItem
            key={name}
            // hasFeedback
            {...formItemProps}
            help={getHelp && getHelp(getFieldsValue([name]))}
          >
            {getFieldDecorator(
              name,
              fieldConfig,
            )(<Component {...inputProps} setFields={setFields} />)}
          </SFormItem>
        )
      })}
      {submitButtonHidden ? null : (
        <CustomButton
          content={submitButtonContent}
          htmlType="submit"
          type="primary"
          {...submitButtonProps}
          disabled={hasErrors}
        />
      )}
    </Form>
  )
}

MemberInviteForm.propTypes = {
  fields: PT.array.isRequired,
  formProps: PT.object,
  submitButtonContent: PT.oneOfType([PT.element, PT.node, PT.string]),
  submitButtonHidden: PT.bool,
  submitButtonProps: PT.object,
  validate: PT.func.isRequired,
}

export default Form.create({ name: 'MemberInviteForm' })(MemberInviteForm)

const hasNoErrors = value =>
  _fp.pipe(
    _fp.map(fn => fn(_.toString(value))),
    _fp.every(_fp.identity),
  )

export const getError = (value, rules, message) => (hasNoErrors(value)(rules) ? undefined : message)
