import React from 'react'
import PT from 'prop-types'
import get from 'lodash/get'
import { Modal } from 'antd'

const VideoModal = props => {
  const visible = get(props, 'visible')
  const onCancel = get(props, 'onCancel')
  const tutorial = get(props, 'tutorial')
  const { videoSrc, title, description } = tutorial

  const modalHeader =
    title || description ? (
      <>
        {title && <h4>{title}</h4>}
        {description && <h5>{description}</h5>}
      </>
    ) : null

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      onCancel={onCancel}
      title={modalHeader}
      className="video-modal"
    >
      <div className="embedded-responsive-wrapper">
        <iframe
          className="embedded-responsive-player"
          src={visible ? videoSrc : ''}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
          title="skill-details-video"
        />
      </div>
    </Modal>
  )
}

VideoModal.prototypes = {
  visible: PT.bool.isRequired,
  onCancel: PT.func.isRequired,
  tutorial: PT.object.isRequired
}

export default VideoModal
