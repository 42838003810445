import React from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/client/react/components'
import FunctionalityContainer from '../FunctionalityContainer'
import ChangePasswordForm from './components/ChangePasswordForm'
import feedback from '../../../../../../../utils/feedback'
import errorService from '../../../../../../../utils/analytics/error'

/**
 * Defines mutation
 */
const changeUniversityClientUserPasswordMutation = gql`
  mutation ChangeUniversityClientUserPassword($data: ChangePasswordInput!) {
    changeUniversityClientUserPassword(data: $data)
  }
`

const ChangePassword = props => {
  /**
   * Generic onSubmit handler factory
   * @returns {Function} Submit handler
   */
  const onSubmit = runMutation => async formData => {
    try {
      const result = await runMutation({
        variables: {
          data: _.pick(formData, ['currentPassword', 'newPassword']),
        },
      })
      if (result) {
        feedback.success({ content: 'Password changed successfully' })
      } else {
        throw new Error('There was an error changing your password!')
      }
    } catch (error) {
      errorService.report(error, 'ChangePassword')
      feedback.error({
        content: error.message,
        title: 'Error changing your password',
      })
    }
  }

  const memoizedOnSubmit = _.memoize(onSubmit)

  return (
    <FunctionalityContainer title="Change Password">
      <Mutation mutation={changeUniversityClientUserPasswordMutation}>
        {runMutation => (
          <ChangePasswordForm onSubmit={memoizedOnSubmit(runMutation)} />
        )}
      </Mutation>
    </FunctionalityContainer>
  )
}

export default ChangePassword
