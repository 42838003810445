import gql from 'graphql-tag'
import CreateTeamForm from './components/CreateTeamForm'
import TeamInfo from './components/TeamInfo'

/**
 * Mutation definition for getting the necessary universityClientFields
 */
export const univeristyClientTeamsQuery = gql`
  query UniversityClientTeams {
    universityClient {
      admins {
        id
        fullName
        email
        isAdmin
      }
      id
      teams {
        ...universityClientTeamFields
      }
      documents {
        ...universityDocumentFields
      }
      reports {
        ...universityReportFields
      }
      members {
        ...universityClientUserFields
      }
    }
  }
  ${TeamInfo.fragments}
  ${CreateTeamForm.fragments}
`

/**
 * Mutation definition for creating a universityClientTeam
 */
export const createUniversityClientTeamMutation = gql`
  mutation CreateUniversityClientTeam($data: UniversityClientTeamInput!) {
    createUniversityClientTeam(data: $data) {
      ...universityClientTeamFields
    }
  }
  ${TeamInfo.fragments}
`

/**
 * Mutation definition for modifying a universityClientTeam
 */
export const modifyUniversityClientTeamMutation = gql`
  mutation ModifyUniversityClientTeam($data: ModifyUniversityClientTeamInput!) {
    modifyUniversityClientTeam(data: $data) {
      ...universityClientTeamFields
    }
  }
  ${TeamInfo.fragments}
`

export const deleteTeamMutation = gql`
  mutation DeleteUniversityClientTeam($teamId: ID!) {
    deleteUniversityClientTeam(teamId: $teamId)
  }
`

export const reassignTeamMemberMutation = gql`
  mutation ReassignTeamMember($data: ReassignTeamMemberInput!) {
    reassignTeamMember(data: $data)
  }
`
