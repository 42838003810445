import { useEffect, useReducer, useCallback } from 'react'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import slice from 'lodash/slice'

import { useOnUpdate } from '../../../../../../hooks/useOnUpdate'
import usePrevious from '../../../../../../hooks/usePrevious'
import { useGraduateOutcomesTrendsData } from '../../../hooks/useGraduateOutcomesTrendsData'
import { useGraduateOutcomesTrendsOptions } from '../../../hooks/useGraduateOutcomesTrendsOptions'

import { TREND_CATEGORIES } from '..'

export const COMPANY_OPTIONS_PAGE_SIZE = 10

/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const COMPANY_SORT_OPTIONS = {
  TRENDING_DOWN: 'trending-down',
  TRENDING_UP: 'trending-up',
  VOLUME_DESC: 'volume-desc',
}

/**
 * @typedef CompanyHiringTrend
 * @type {object}
 * @property { number } alumni
 * @property { number } year
 * @property { number } y
 */

/**
 * @typedef CompanyOption
 * @type {object}
 * @property { string } name
 * @property { number } doc_count
 * @property { string } company_id
 * @property { Array<string> } skills
 * @property { string } url
 * @property { number } volume
 * @property { Array<CompanyHiringTrend> } trends
 * @property { number } trendSlope
 */

/**
 * @typedef CompanyTrendsState
 * @type {object}
 * @property {Array<CompanyOption>} stitchedCompanies
 * @property {string} selectedSortOption
 * @property {number} page
 */

/**
 * @type {CompanyTrendsState}
 */
const initialState = {
  page: 1,
  selectedSortOption: COMPANY_SORT_OPTIONS.VOLUME_DESC,
  stitchedCompanies: [],
}

function reducer(state, newState) {
  return {
    ...state,
    ...newState,
  }
}

export const useCompanyTrendsData = ({
  clientName,
  finalTaxonomy,
  topLevelFilters,
  universitiesToSubmit,
}) => {
  const {
    trendOptions,
    loadingGraduateOutcomesTrendsOptions,
    graduateOutcomesTrendOptionsError,
  } = useGraduateOutcomesTrendsOptions({
    clientName,
    finalTaxonomy,
    topLevelFilters,
    trendCategory: TREND_CATEGORIES.COMPANY,
    universitiesToSubmit,
  })

  /**
   * @type{[CompanyTrendsState,  React.Dispatch<any>]}
   */
  const [state, dispatch] = useReducer(reducer, initialState)

  const topCompanyOptions = get(trendOptions, 'topCompanies', [])

  const {
    getTrendsData,
    trendsDataError,
    loadingTrendsData,
    trendsData,
  } = useGraduateOutcomesTrendsData()

  useOnUpdate(topCompanyOptions, () => {
    const companyNames = topCompanyOptions.map(company => company.name)
    const validUniversites = universitiesToSubmit.filter(university => !!university.length)
    if (validUniversites && validUniversites.length > 0 && companyNames.length) {
      getTrendsData({
        variables: {
          category: TREND_CATEGORIES.COMPANY,
          filters: {
            ...omit(topLevelFilters, 'universities'),
            taxonomy: finalTaxonomy,
            universities: validUniversites,
          },
          targets: companyNames,
        },
      })
    }
  })

  const companyHiringTrends = get(trendsData, 'companyHiringTrends')
  const docCount = get(trendsData, 'doc_count')

  const stitchTogetherCompanyData = (topCompanyOptions, companyHiringTrends) => {
    const combinedData = topCompanyOptions.map(companyOption => {
      const matchingTrends = companyHiringTrends.find(trend => companyOption.name === trend.name)
      return {
        ...companyOption,
        ...matchingTrends,
      }
    })
    return combinedData
  }

  const prevTopCompanyOptions = usePrevious(topCompanyOptions)
  const prevCompanyHiringTrends = usePrevious(companyHiringTrends)

  useEffect(() => {
    if (!isEqual(prevCompanyHiringTrends, companyHiringTrends) && !!companyHiringTrends) {
      const stitchedCompanies = stitchTogetherCompanyData(topCompanyOptions, companyHiringTrends)
      dispatch({ stitchedCompanies })
    }
  }, [prevTopCompanyOptions, prevCompanyHiringTrends, topCompanyOptions, companyHiringTrends])

  const setCompanySortOption = val => dispatch({ page: 1, selectedSortOption: val })

  /**
   * @returns {SortedCompanies}
   */
  const getSortedCompanyGroups = useCallback(() => {
    const sortedCompanies = {
      [COMPANY_SORT_OPTIONS.TRENDING_DOWN]: orderBy(
        state.stitchedCompanies,
        ['trendSlope'],
        ['asc'],
      ),
      [COMPANY_SORT_OPTIONS.TRENDING_UP]: orderBy(
        state.stitchedCompanies,
        ['trendSlope'],
        ['desc'],
      ),
      [COMPANY_SORT_OPTIONS.VOLUME_DESC]: orderBy(state.stitchedCompanies, ['volume'], ['desc']),
    }

    return sortedCompanies
  }, [state.stitchedCompanies])

  const companies = slice(
    getSortedCompanyGroups()[state.selectedSortOption],
    0,
    state.page * COMPANY_OPTIONS_PAGE_SIZE,
  )

  const pageUp = () => dispatch({ page: state.page + 1 })

  return {
    companies,
    docCount,
    getTrendsData,
    graduateOutcomesTrendOptionsError,
    loadingGraduateOutcomesTrendsOptions,
    loadingTrendsData,
    page: state.page,
    pageUp,
    selectedCompanies: getSortedCompanyGroups()[state.selectedSortOption],
    selectedSortOption: state.selectedSortOption,
    setCompanySortOption,
    totalCount: state.stitchedCompanies.length,
    trendsData,
    trendsDataError,
  }
}
