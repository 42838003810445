import React from 'react'

import get from 'lodash/get'

import { BUILDING_ICON_URL } from '../../../../utils/applicationConstants'
import { getLogoSrc } from '../../../../utils/stringManipulation'

const Logo = props => {
  const url = get(props, 'url')
  const additionalStyles = get(props, 'additionalStyles')
  const margin = get(props, 'margin')
  const children = get(props, 'children')

  const childComponents = (
    <div style={url ? { marginLeft: margin } : {}}>{children}</div>
  )

  if (!url) return childComponents

  const addDefaultSrc = e => {
    if (e.target.src !== BUILDING_ICON_URL) {
      e.target.src = BUILDING_ICON_URL
    }
  }

  const src = url.substring(0, 4) === 'http' ? url : getLogoSrc(url)

  const defaultStyle = { position: 'absolute' }

  const style = additionalStyles
    ? {
        ...defaultStyle,
        ...additionalStyles,
      }
    : defaultStyle

  return (
    <>
      <img
        src={src}
        alt="university logo"
        style={style}
        className="logo-circle"
        onError={addDefaultSrc}
      />
      {childComponents}
    </>
  )
}

export default Logo
