import React from 'react'

import { Mutation } from '@apollo/client/react/components'
import { Icon, Modal } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'
import PT from 'prop-types'
import validator from 'validator'

import errorService from '../../../../../../../../../utils/analytics/error'
import {
  extractIdsFromList,
  hasLength,
} from '../../../../../../../../../utils/data/utils'
import feedback from '../../../../../../../../../utils/feedback'
import * as tags from '../../tags'

import MemberInviteForm from './components/MemberInviteForm'

/**
 * Gets base form fields
 */

/**
 * Mutation to run
 */
const createUniversityClientUserInvitationsMutation = gql`
  mutation CreateUniversityClientUserInvitationsMutation(
    $data: CreateUniversityClientUserInvitationsInput!
  ) {
    createUniversityClientUserInvitations(data: $data) {
      invitations {
        email
      }
    }
  }
`

const createDemoAccountUserInvitationMutation = gql`
  mutation CreateDemoAccountUserInvitationMutation(
    $data: CreateDemoAccountUserInvitationsInput!
  ) {
    createDemoAccountUserInvitation(data: $data) {
      createdAt
      email
      id
      token
      isAdmin
    }
  }
`

const CreateMemberForm = props => {
  const { clientName, modalProps, refetch, teams, admins, members, invitations } = props

  const getEmailHelp = emails => {
    if (!emails || !emails.length) {
      return 'Email required'
    }
    const invalidEmails = emails.filter(email => !validator.isEmail(email))
    if (invalidEmails.length > 0) {
      return `${invalidEmails[0]} is not a valid email`
    }
    // Check if member(s) already exists/has been invited
    const emailsInUse = [...admins, ...members, ...invitations].map(({ email }) => email)
    const existingMemberEmails = emails.filter(email => emailsInUse.includes(email))
    const multipleExistingMembers = existingMemberEmails.length > 1
    if (existingMemberEmails.length > 0) {
      return (
        <div>
          <Icon type="warning" />
          The following {multipleExistingMembers ? 'emails are' : 'email is'} already in use:
          {existingMemberEmails.map(email => (
            <span key={email} style={{ display: 'block', paddingLeft: '25px' }}>• {email}</span>
          ))}
        </div>
      )
    }
  }

  const validate = ({ email = [], teams = [], isAdmin }) => {
    return {
      email: getEmailHelp(email),
      teams:
        isAdmin || hasLength(teams)
          ? undefined
          : 'Must select at least one team',
    }
  }

  const demoAccount =
    clientName === process.env.REACT_APP_PUBLIC_DEMO_CLIENTNAME

  const onSubmit = sendInvitation => async formValues => {
    try {
      const { email, isAdmin, message, teams } = _.pick(formValues, [
        'email',
        'isAdmin',
        'message',
        'teams',
      ])
      const errors = validate({ email, isAdmin, teams })
      if (errors.email || errors.teams) {
        console.log(errors)
        return
      }
      const teamIds = extractIdsFromList(teams)
      await sendInvitation({
        variables: {
          data: { emails: email, isAdmin: isAdmin || false, message, teamIds },
        },
      })
      refetch()
    } catch (error) {
      errorService.report(error, 'sendInvitation')
      feedback.error({
        content: error.message,
        title: 'Error sending invitation',
      })
    } finally {
      modalProps.closeModal()
    }
  }

  const memoizedOnSubmit = _.memoize(onSubmit)

  return (
    <Modal
      {...modalProps}
      destroyOnClose
      footer={null}
      onCancel={modalProps.closeModal}
    >
      {demoAccount ? (
        <Mutation
        mutation={createDemoAccountUserInvitationMutation}
        update={(store, { data: { createDemoAccountUserInvitation } }) => {
          const cachedData = store.readQuery({
            query: tags.univeristyClientMembersQuery,
          })
          cachedData.universityClient.invitations.push(
            createDemoAccountUserInvitation,
          )
          store.writeQuery({
            data: cachedData.universityClient,
            query: tags.univeristyClientMembersQuery,
          })
          feedback.success({ content: 'Invitation created successfully' })
        }}
      >
          {sendInvitation => {
            return (
              <MemberInviteForm
                submitForm={memoizedOnSubmit(sendInvitation)}
                submitButtonContent="Invite Members"
                getFieldDecorator
                validate={validate}
                getEmailHelp={getEmailHelp}
                teams={teams}
              />
            )
          }}
        </Mutation>
      ) : (
        <Mutation
          mutation={createUniversityClientUserInvitationsMutation}
          update={(
            store,
            { data: { createUniversityClientUserInvitations } },
          ) => {
            const cachedData = store.readQuery({
              query: tags.univeristyClientMembersQuery,
            })
            _.get(createUniversityClientUserInvitations, 'invitations')
            const currentInvitations = [
              ...cachedData.universityClient.invitations,
            ]
            const updatedInvitations = [
              ...currentInvitations,
              ..._.get(createUniversityClientUserInvitations, 'invitations'),
            ]

            store.writeQuery({
              data: updatedInvitations,
              query: tags.univeristyClientMembersQuery,
            })
            feedback.success({ content: 'Invitation created successfully' })
          }}
        >
          {sendInvitation => {
            return (
              <MemberInviteForm
                submitForm={memoizedOnSubmit(sendInvitation)}
                submitButtonContent="Invite Member"
                submitButtonProps={{ block: true, style: { marginTop: 16 } }}
                validate={validate}
                getEmailHelp={getEmailHelp}
                teams={teams}
              />
            )
          }}
        </Mutation>
      )}
    </Modal>
  )
}

const TeamPT = PT.shape({
  id: PT.string.isRequired,
  name: PT.string.isRequired,
})

CreateMemberForm.propTypes = {
  clientName: PT.string.isRequired,
  teams: PT.arrayOf(TeamPT).isRequired,
}

export default CreateMemberForm
