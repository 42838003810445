import { useEffect } from 'react'

import * as FullStory from '@fullstory/browser'
import gql from 'graphql-tag'
import get from 'lodash/get'
import getArrayNthElement from 'lodash/nth'

import intercom from '../../utils/intercom/intercomUtils'
import errorService from '../analytics/error'
import feedback from '../feedback'
import { client } from '../graphql/configuration'
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from '../routing/routes'
import {
  clearDemoDimNames,
  clearDemoSavedSearchName,
  clearDemoUrl,
  clearTaxonomy,
  clearUserViewEmail,
} from '../superUserDataInteraction'

const universityClientUserProfileQuery = gql`
  query UniversityClientUserProfile {
    universityClientUserProfile {
      clientName
      createdAt
      department
      departmentDetail
      documents {
        id
        name
      }
      email
      firstName
      id
      isAdmin
      isSystemAdmin
      jobTitle
      lastName
      lastLogin
      reports {
        id
        name
        url
        type
      }
      updatedAt
    }
  }
`

export const logoutUserMutation = gql`
  mutation LogoutUniversityReportUser {
    logoutUniversityReportUser
  }
`

const AUTHORIZATION_TOKEN_PATH = 'authorizationToken'

export const clearAuthorizationToken = () =>
  window['localStorage'].removeItem(AUTHORIZATION_TOKEN_PATH)

export const getAuthorizationToken = () =>
  window['localStorage'].getItem(AUTHORIZATION_TOKEN_PATH) || null

export const isAuthenticated = () => !!getAuthorizationToken()

export const setAuthorizationToken = authorizationToken =>
  window['localStorage'].setItem(AUTHORIZATION_TOKEN_PATH, authorizationToken)

const isAuthenticatedRoute = pathname =>
  !Object.values(UNAUTHENTICATED_ROUTES).includes(pathname) && pathname !== '/'

const isSystemAdminRoute = pathname =>
  [AUTHENTICATED_ROUTES.DEMO, AUTHENTICATED_ROUTES.USER_VIEWS].includes(pathname)

const isReactReportRoute = pathname =>
  [
    AUTHENTICATED_ROUTES.GRADUATE_OUTCOMES,
    AUTHENTICATED_ROUTES.GRADUATE_OUTCOMES_EMPLOYERS,
    AUTHENTICATED_ROUTES.GRADUATE_OUTCOMES_SKILLS,
    AUTHENTICATED_ROUTES.GRADUATE_EXPLORER,
  ].includes(pathname)

const useCreateListener = (history, move) =>
  useEffect(() => {
    history.listen(location => move(history, location))
  }, [history, move])

export const useProtectAuthenticatedRoutes = async history => {
  const move = (history, { pathname }) => {
    const shouldMove = !isAuthenticated() && isAuthenticatedRoute(pathname)
    if (shouldMove) {
      history.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
    }
  }

  const checkSystemAdminAccess = (userData, history, { pathname }) => {
    const isSystemAdmin = get(userData, 'universityClientUserProfile.isSystemAdmin', false)

    if (!isSystemAdmin && isSystemAdminRoute(pathname))
      history.replace(AUTHENTICATED_ROUTES.DASHBOARD)
  }

  const checkReactReportAccess = (userData, history, { pathname }) => {
    if (isReactReportRoute(pathname)) {
      const reportsForUser = get(userData, 'universityClientUserProfile.reports', [])
        .filter(r => r?.type === 'react')
        .map(r => r?.name.toLowerCase().replace(' ', '-'))

      const reportToAccess = getArrayNthElement(
        pathname === AUTHENTICATED_ROUTES.REQUISITIONS
          ? AUTHENTICATED_ROUTES.TALENT_EYE.split('/')
          : pathname.split('/'),
        2,
      )

      if (!reportsForUser.includes(reportToAccess)) history.replace(AUTHENTICATED_ROUTES.DASHBOARD)
    }
  }

  move(history, history.location)

  client
    .query({
      fetchPolicy: 'cache-first',
      query: universityClientUserProfileQuery,
    })
    .then(({ loading, data }) => {
      if (!loading) {
        checkSystemAdminAccess(data, history, history.location)
        checkReactReportAccess(data, history, history.location)

        const currentPath = history.location.pathname.substring(
          history.location.pathname.lastIndexOf('/') + 1,
        )
        const {
          clientName,
          createdAt,
          department,
          departmentDetail,
          email,
          firstName,
          lastName,
          id,
          isAdmin,
          isSystemAdmin,
          jobTitle,
          updatedAt,
          lastLogin,
        } = data.universityClientUserProfile

        // Send user data to intercom
        // Create new hmac whenever we first send the user data
        intercom.createNewHMAC(id)
        intercom.intercomBoot({
          clientName,
          createdAt,
          currentPath,
          department,
          departmentDetail,
          email,
          firstName,
          isAdmin,
          isSystemAdministrator: isSystemAdmin,
          jobTitle,
          lastLogin,
          lastName,
          updatedAt,
          user_id: id,
        })
      }
    })

  useCreateListener(history, move)
}

export const useProtectUnauthenticatedRoutes = history => {
  const move = (history, { pathname }) => {
    const shouldMove = isAuthenticated() && !isAuthenticatedRoute(pathname)
    if (shouldMove) {
      history.replace('/app')
    }
  }

  move(history, history.location)

  useCreateListener(history, move)
}

export const logout = async () => {
  try {
    await client.mutate({
      fetchPolicy: 'no-cache',
      mutation: logoutUserMutation,
    })
    client.cache.reset()
  } catch (error) {
    console.log(error)
    errorService.report(error, 'logoutUserMutation')
  } finally {
    document.location.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
    FullStory.anonymize()
    FullStory.shutdown()
    // End intercom session for the user and start a fresh session
    intercom.intercomEndSession()
    intercom.intercomStartFreshSession()
    clearDemoSavedSearchName()
    clearDemoDimNames()
    clearDemoUrl()
    clearTaxonomy()
    clearUserViewEmail()
    clearAuthorizationToken()
  }
}

let loggingOut = false
export const logoutFromExpiredSession = async () => {
  if (!loggingOut) {
    loggingOut = true
    client.stop()
    client.cache.reset()
    clearDemoSavedSearchName()
    clearDemoDimNames()
    clearDemoUrl()
    clearTaxonomy()
    clearUserViewEmail()
    clearAuthorizationToken()
    await client.clearStore()
    feedback.info({
      content: 'Please login again!',
      title: 'Session Expired',
    })
    FullStory.anonymize()
    FullStory.shutdown()
    // End intercom session for the user and start a fresh session
    intercom.intercomEndSession()
    intercom.intercomStartFreshSession()
  }
}
