import React, { useEffect, useState } from 'react'

import { Col, Result, Skeleton, Spin } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import truncate from 'lodash/truncate'

import { TREND_CATEGORIES } from '../..'
import usePrevious from '../../../../../../../hooks/usePrevious'
import { pickWithDefaults } from '../../../../../../../utils/data/utils'
import HiringTrendsChart from '../../../../components/HiringTrendsChart'
import { useGraduateOutcomesTrendsData } from '../../../../hooks/useGraduateOutcomesTrendsData'
import { useGraduateOutcomesTrendsOptions } from '../../../../hooks/useGraduateOutcomesTrendsOptions'
import { useCurrentlyLoaded } from '../../../hooks/useCurrentlyLoaded'
import TrendsCard from '../TrendsCard'

import IndustryOptions from './IndustryOptions'

export default function IndustryTrends(props) {
  const {
    clientName,
    finalTaxonomy,
    loadingTopLevelData,
    topLevelFilters,
    universitiesToSubmit,
    universityNameDisplay,
  } = pickWithDefaults(props, {
    clientName: '',
    finalTaxonomy: '',
    loadingTopLevelData: false,
    setShowingLoaderForTrendsTab: noop,
    showingLoaderForTrendsTab: false,
    topLevelFilters: {},
    universitiesToSubmit: [],
    universityNameDisplay: '',
  })

  const [selectedIndustryOption, setSelectedIndustryOption] = useState(null)
  const [loadingInitialTrends, setLoadingInitialTrends] = useState(true)

  const {
    loadingGraduateOutcomesTrendsOptions,
    graduateOutcomesTrendOptionsError,
    trendOptions,
    docCount,
  } = useGraduateOutcomesTrendsOptions({
    clientName,
    finalTaxonomy,
    topLevelFilters,
    trendCategory: TREND_CATEGORIES.INDUSTRIES,
    universitiesToSubmit,
  })
  const {
    getTrendsData,
    loadingTrendsData,
    trendsDataError,
    trendsData,
  } = useGraduateOutcomesTrendsData({
    setLoadingInitialTrends,
  })

  const topIndustries = get(trendOptions, 'top_industries', [])
  const prevTopIndustries = usePrevious(topIndustries)
  useEffect(() => {
    if (!isEqual(prevTopIndustries, topIndustries)) {
      const firstOption = topIndustries[0]
      const topIndustryName = get(firstOption, 'key')
      if (topIndustryName && topIndustryName !== selectedIndustryOption) {
        setSelectedIndustryOption(topIndustryName)
      }
    }
  }, [topIndustries, selectedIndustryOption, prevTopIndustries])

  const filters = {
    ...omit(topLevelFilters, 'universities'),
    taxonomy: finalTaxonomy,
    universities: universitiesToSubmit,
  }
  const prevSelectedIndustryOption = usePrevious(selectedIndustryOption)
  const prevFilters = usePrevious(filters)
  useEffect(() => {
    if (
      selectedIndustryOption &&
      (!isEqual(prevSelectedIndustryOption, selectedIndustryOption) ||
        !isEqual(prevFilters, filters))
    ) {
      getTrendsData({
        variables: {
          category: TREND_CATEGORIES.INDUSTRIES,
          filters,
          targets: [selectedIndustryOption],
        },
      })
    }
  }, [filters, getTrendsData, prevFilters, prevSelectedIndustryOption, selectedIndustryOption])

  const industryHiringTrends = get(trendsData, 'industry_hiring_trends', [])
  const loadedTrends = useCurrentlyLoaded(industryHiringTrends)

  const handleIndustryClick = value => {
    if (value === selectedIndustryOption) setSelectedIndustryOption(null)
    else setSelectedIndustryOption(value)
  }

  const loadingAll =
    loadingTopLevelData || loadingGraduateOutcomesTrendsOptions || loadingTrendsData
  const hasError = trendsDataError || graduateOutcomesTrendOptionsError
  const noResults =
    (!loadingAll && !topIndustries.length && !industryHiringTrends.length) || hasError
  const lowVolume = !loadingAll && docCount < 100

  return (
    <TrendsCard>
      <Col md={8}>
        <Meta
          title="Industries"
          description="Click any industry to display hiring trends on the right."
        />
        <div className="click-list">
          <Skeleton
            loading={loadingGraduateOutcomesTrendsOptions}
            paragraph={{ rows: 12 }}
            title={false}
          >
            <IndustryOptions
              loadingTrendsData={loadingTrendsData}
              options={topIndustries}
              selectedOption={selectedIndustryOption}
              handleIndustryClick={handleIndustryClick}
            />
          </Skeleton>
        </div>
      </Col>
      <Col md={16}>
        <Meta
          title="Industry Hiring Trends"
          description="The industries of the companies your graduates work for, as a percentage of total graduates. (20 year trend)"
        />
        {lowVolume ? (
          <Result
            title="Sample size is too small for trend analysis."
            subTitle="Please expand your filter criteria to show trends."
          />
        ) : noResults ? (
          <Result
            title="Sorry, no data available with current filters."
            subTitle="Please try clearing some filters."
          />
        ) : (
          <Spin spinning={loadingInitialTrends}>
            <HiringTrendsChart
              trends={loadedTrends}
              referenceKey={truncate(universityNameDisplay, {
                length: 66,
              })}
            />
          </Spin>
        )}
      </Col>
    </TrendsCard>
  )
}
