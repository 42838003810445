import React, { useCallback, useMemo } from 'react'
import { Form, Table } from 'antd'
import styled from 'styled-components'
import getfp from 'lodash/fp/get'
import isArray from 'lodash/isArray'
import { getHelp, getValidateStatus } from '../../../utils/forms'

const Container = styled.div``

const FormTableSelectorInput = props => {
  const { input, label, meta, rowSelectionProps, ...rest } = props
  const { onChange, value = [] } = input

  const onChangeCallback = useCallback(
    (_selectedRowKeys, selectedRows) => {
      onChange(selectedRows)
    },
    [onChange]
  )

  const selectedRowKeys = useMemo(
    () => (!isArray(value) ? [] : value.map(getfp('id'))),
    [value]
  )

  const rowSelection = {
    onChange: onChangeCallback,
    selectedRowKeys,
    ...rowSelectionProps
  }

  return (
    <Container>
      <Form.Item
        hasFeedback
        help={getHelp(meta)}
        label={label}
        validateStatus={getValidateStatus(meta)}
      >
        <Table {...rest} rowSelection={rowSelection} />
      </Form.Item>
    </Container>
  )
}

FormTableSelectorInput.propTypes = {}

export default FormTableSelectorInput
