import React from 'react'
import PT from 'prop-types'
import _ from 'lodash'
import { Table } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 48px;
`

const TableSelectorInput = props => {
  const { columns, data, onChange, ...rest } = props
  const disabled = _.get(props, 'disabled')

  const rowSelection = disabled
    ? null
    : {
        onChange: (_selectedRowKeys, selectedRows) => {
          onChange(selectedRows)
        },
        selectedRowKeys:
          rest.value === ''
            ? []
            : _.get(rest, 'value', []).map(val => _.get(val, 'id'))
      }

  return (
    <Container>
      <Table
        {...rest}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      />
    </Container>
  )
}

const ColumnPT = PT.shape({
  dataIndex: PT.string.isRequired,
  title: PT.string.isRequired
})

TableSelectorInput.propTypes = {
  data: PT.array.isRequired,
  columns: PT.arrayOf(ColumnPT).isRequired
}

export default TableSelectorInput
