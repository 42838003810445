import React, { useState } from 'react'

import { Row, Col, Popover, Typography, Icon } from 'antd'
import get from 'lodash/get'
import PT from 'prop-types'

import colors from '../../../../components/hocs/colors'
import errorService from '../../../../utils/analytics/error'
import { client } from '../../../../utils/graphql/configuration'
import { capitalize } from '../../../../utils/stringManipulation'
import { skillDescriptionQuery } from '../../GraduateOutcomes/gqlQueries'

const { Title, Paragraph } = Typography

export const SkillsBars = props => {
  const [skillDescription, setSkillDescription] = useState(null)
  const { name: skillName = '' } = props

  const getSkillDescription = async skillName => {
    try {
      const result = await client.query({
        fetchPolicy: 'network-only',
        query: skillDescriptionQuery,
        variables: { skillName },
      })
      const description = get(result, 'data.skillDescription')
      setSkillDescription(description)
    } catch (error) {
      errorService.report(error, 'skillDescriptionQuery')
      console.log(error)
      setSkillDescription('Description Unavailable')
    }
  }

  const handleVisibleChange = visible => {
    if (visible && !skillDescription) {
      getSkillDescription(skillName)
    }
  }

  return (
    <Row style={{ marginBottom: 10 }} className="horizontal-skill-bars">
      <Col md={6}>
        <div style={{ alignItems: 'center', display: 'flex', marginTop: 12 }}>
          <Popover
            placement="rightTop"
            title={
              <Title level={4}>
                {capitalize(skillName)}
                {'  '}
                <a
                  href={`https://www.youtube.com/results?search_query=${skillName}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Icon
                    type="youtube"
                    theme="filled"
                    style={{ color: '#ff1800' }}
                  />
                </a>
              </Title>
            }
            content={
              <Paragraph className="skill-description-container">
                {skillDescription}
              </Paragraph>
            }
            onVisibleChange={handleVisibleChange}
            overlayStyle={{ width: 800 }}
          >
            <div style={{ fontSize: 14, fontWeight: '600', marginTop: 5 }}>
              {capitalize(skillName)}
            </div>
          </Popover>
        </div>
      </Col>
      {props.values.map((child, index) => (
        <Col
          key={`${get(child, 'value', 0)}${index}`}
          md={6}
          className="skill-bar-column"
        >
          <div
            key={get(child, 'key')}
            style={{
              background: colors.gray4,
              borderRadius: 6,
              marginTop: 5,
            }}
          >
            <div
              className="scale-bars-skills"
              style={{
                background: colors[get(child, 'name', '#fff')],
                borderColor: colors[get(child, 'name', '#fff')],
                borderRadius: 5,
                height: 15,
                minWidth: 10,
                opacity: 0.8,
                padding: '0 5px',
                width: `${get(child, 'value', 0)}%`,
              }}
            />
          </div>
        </Col>
      ))}
    </Row>
  )
}

SkillsBars.propTypes = {
  description: PT.string,
  name: PT.string,
  values: PT.array,
}

export default SkillsBars
