import React from 'react'

import { Mutation } from '@apollo/client/react/components'
import { Button, List } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import errorService from '../../../../../../../../../utils/analytics/error'
import feedback from '../../../../../../../../../utils/feedback'
import * as tags from '../../tags'

const Container = styled(List.Item)`
  border-bottom: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px 24px 8px 24px !important;
  width: 100%;
`

const cancelUniversityClientUserInvitationMutation = gql`
  mutation CancelUniversityClientUserInvitation(
    $data: CancelUniversityClientUserInvitationInput!
  ) {
    cancelUniversityClientUserInvitation(data: $data)
  }
`

const PendingInvitationListItem = props => {
  const { createdAt, email, id, isAdmin } = props

  const onDelete = cancelInvitation => async () => {
    try {
      await cancelInvitation({ variables: { data: { id } } })
      feedback.success({ content: 'Invitation cancelled' })
    } catch (error) {
      errorService.report(error, 'cancelInvitation')
      feedback.error({
        content: error.message,
        title: 'Error cancelling the invitation',
      })
    }
  }

  const memoizedOnDelete = _.memoize(onDelete)

  return (
    <Container>
      <List.Item.Meta
        title={`${isAdmin ? 'Admin ' : ''} Invite sent to: ${email}`}
        description={`Sent at: ${moment
          .utc(createdAt)
          .local()
          .format('MM/DD/YYYY h:mm A')}`}
      />
      <Mutation
        mutation={cancelUniversityClientUserInvitationMutation}
        refetchQueries={() => [{ query: tags.univeristyClientMembersQuery }]}
      >
        {cancelInvitation => (
          <Button
            icon="delete"
            type="danger"
            onClick={memoizedOnDelete(cancelInvitation)}
          >
            Cancel Invite
          </Button>
        )}
      </Mutation>
    </Container>
  )
}

PendingInvitationListItem.propTypes = {}

export default PendingInvitationListItem
