import * as FullStory from '@fullstory/browser'
import SentryFullStory from '@sentry/fullstory'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const isProduction = process.env.NODE_ENV === 'production'
const environment = process.env.REACT_APP_ENVIRONMENT
const sampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE

const configure = () => {
  if (isProduction) {
    Sentry.init({
      attachStacktrace: true,
      beforeSend(event) {
        const {
          exception: { values },
        } = event

        if (values && values.length === 1) {
          const isNonErrorException =
            // non-error object sent to Sentry
            values[0].value.includes('Non-Error promise rejection captured with keys') ||
            // common/benign, ResizeObserver could not deliver all observations within a single animation frame
            values[0].value.includes('ResizeObserver loop limit exceeded')
          if (isNonErrorException) {
            return null
          }
        }
        return event
      },
      dsn: process.env.REACT_APP_SENTRY_PUBLIC_DSN,
      environment,
      integrations: [
        new SentryFullStory('steppingblocks', { client: FullStory }),
        new BrowserTracing({
          tracePropagationTargets: [
            process.env.REACT_APP_API_HOST,
            `${process.env.REACT_APP_API_HOST}/*`,
          ],
        }),
      ],
      normalizeDepth: 10,
      tracesSampler: samplingContext => {
        // always inherit from parent trace
        if (samplingContext.parentSampled !== undefined) {
          return samplingContext.parentSampled
        }
        return sampleRate
      },
    })
  }
}

const setUserContext = user => {
  if (isProduction) {
    Sentry.configureScope(scope => {
      if (user) {
        scope.setUser(user)
        scope.setTag('user', user)
      }
    })
  }
}

const report = (error, query) => {
  if (isProduction) {
    Sentry.withScope(scope => {
      scope.setTag('query', query)
      Sentry.captureException(new Error(error))
    })
  }
}

export default {
  configure,
  report,
  setUserContext,
}
