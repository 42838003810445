import React from 'react'
import map from 'lodash/map'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import Highcharts from 'highcharts'
import highchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'

highchartsExporting(Highcharts)

const SkillsBarChart = ({
  containerProps,
  companyName,
  data,
  universityName,
  title
}) => {
  const max = Math.max(
    Math.abs(
      get(
        maxBy(data, o => {
          return Math.abs(o.companyPercent)
        }),
        'companyPercent'
      )
    ),
    get(
      maxBy(data, o => {
        return o.universityPercent
      }),
      'universityPercent'
    )
  )

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: title
    },
    exporting: {
      enabled: false
    },
    subtitle: {
      text: ''
    },
    xAxis: [
      {
        categories: map(data, 'name'),
        reversed: true,
        labels: {
          step: 1
        }
      },
      {
        // mirror axis on right side
        opposite: true,
        reversed: false,
        categories: map(data, 'name'),
        linkedTo: 0,
        min: -20,
        max: 20,
        labels: {
          step: 1
        }
      }
    ],
    yAxis: [
      {
        labels: {
          formatter: function() {
            return this.value === 0 ? '' : `${Math.abs(this.value)}`
          }
        },
        width: '50%',
        min: -max
      },
      {
        width: '50%',
        left: '50%',
        offset: 0,
        max: max
      }
    ],
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
    tooltip: {
      formatter: function() {
        return (
          '<b>' +
          this.point.category +
          '</b><br/>' +
          this.series.name +
          ': ' +
          Highcharts.numberFormat(Math.abs(this.point.y), 0) +
          '%'
        )
      }
    },
    credits: {
      enabled: false // this is to not show highcharts.com text
    },
    series: keys(head(data))
      .filter(key => key !== 'name' && key !== '__typename' && key !== 'key')
      .map(key => {
        const obj = {
          name: key === 'companyPercent' ? companyName : universityName,
          data: map(data, key)
        }
        if (key === 'universityPercent') obj.yAxis = 1
        return obj
      })
  }

  return (
    <div>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default SkillsBarChart
