import React, { useState } from 'react'

import { Radio, Icon } from 'antd'

import colors from '../../../../components/hocs/colors'
import sendFullStoryEvent from '../../../../utils/analytics/sendFullStoryEvent'
import fullstory from '../../../../utils/constants/fullstory'
import { COMPANY_SORT_OPTIONS } from '../tabs/EmployerTrends/hooks/useCompanyTrends'

const CompanyTrendSorter = ({ currentCompanies, onChange }) => {
  const [selectedFilter, setSelectedFilter] = useState('')

  return (
    <div style={{ float: 'right' }}>
      <Radio.Group
        onChange={e => {
          onChange(e.target.value)
          const eventProperties = {
            trend_sorter_str: selectedFilter,
          }
          sendFullStoryEvent(fullstory.eventNames.trendingClick, eventProperties)
        }}
      >
        <Radio.Button
          checked={currentCompanies === COMPANY_SORT_OPTIONS.VOLUME_DESC}
          value={COMPANY_SORT_OPTIONS.VOLUME_DESC}
        >
          <Icon
            type="down-circle"
            style={{
              color: colors.blue1,
              fontSize: 18,
              opacity: 0.8,
              verticalAlign: '0.15em',
            }}
          />
          <span className="legend-label" onClick={() => setSelectedFilter('Volume')}>
            Volume
          </span>
        </Radio.Button>
        <Radio.Button
          checked={currentCompanies === COMPANY_SORT_OPTIONS.TRENDING_UP}
          value={COMPANY_SORT_OPTIONS.TRENDING_UP}
        >
          <Icon
            type="rise"
            style={{
              color: colors.green1,
              fontSize: 18,
              opacity: 0.8,
              verticalAlign: '0.15em',
            }}
          />
          <span className="legend-label" onClick={() => setSelectedFilter('Trending Up')}>
            Trending Up
          </span>
        </Radio.Button>
        <Radio.Button
          checked={currentCompanies === COMPANY_SORT_OPTIONS.TRENDING_DOWN}
          value={COMPANY_SORT_OPTIONS.TRENDING_DOWN}
        >
          <Icon
            type="fall"
            style={{
              color: colors.red1,
              fontSize: 18,
              opacity: 0.8,
              verticalAlign: '0.15em',
            }}
          />
          <span className="legend-label" onClick={() => setSelectedFilter('Trending Down')}>
            Trending Down
          </span>
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default CompanyTrendSorter
