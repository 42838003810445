import React from 'react'

import { Col, Row, Tabs } from 'antd'

import CompanyCandidateList from './CompanyCandidateList'

export const CandidateTabs = ({ topAlumni, topRecruiters }) => {
  const { TabPane } = Tabs

  return (
    <Row>
      <Col>
        <Tabs defaultActiveKey="1" className="sidebar-tabs">
          {topAlumni.length && (
            <TabPane tab="Top Alumni" key="1" className="sidebar-tab">
              <CompanyCandidateList
                candidates={topAlumni}
                // handleCandidateClick={getCandidateDetails}
              />
            </TabPane>
          )}

          {topRecruiters.length && (
            <TabPane tab="Top Recruiters" key="2" className="sidebar-tab">
              <CompanyCandidateList
                candidates={topRecruiters}
                // handleCandidateClick={getCandidateDetails}
              />
            </TabPane>
          )}
        </Tabs>
      </Col>
    </Row>
  )
}

export default CandidateTabs
