import React, { useState } from 'react'
import { Icon, Menu, Card } from 'antd'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { MANAGE_TEAM_ROUTES } from '../../../../../utils/routing/routes'

const menuOptions = [
  {
    title: 'Teams',
    icon: 'team',
    route: MANAGE_TEAM_ROUTES.TEAMS
  },
  {
    title: 'Members',
    icon: 'usergroup-add',
    route: MANAGE_TEAM_ROUTES.MEMBERS
  }
]

const Container = styled(Card)``

const StyledMenu = styled(Menu)`
  border-right: none !important;
`

const ComponentMenu = props => {
  const [activeKey, setActiveKey] = useState(props.history.location.pathname)

  return (
    <Container bordered bodyStyle={{ padding: 8 }}>
      <StyledMenu
        selectedKeys={[activeKey]}
        onSelect={({ key }) => {
          setActiveKey(key)
          props.history.push(key)
        }}
      >
        {menuOptions.map(({ icon, title, route }) => (
          <Menu.Item key={route}>
            <Icon type={icon} />
            {title}
          </Menu.Item>
        ))}
      </StyledMenu>
    </Container>
  )
}

ComponentMenu.propTypes = {}

export default withRouter(ComponentMenu)
