import React from 'react'

import { Card, Icon, Skeleton } from 'antd'
import PT from 'prop-types'

import SimpleBar from '../../../../../../components/charts/SimpleBar'

const { Meta } = Card

const BarFilters = ({
  data,
  description,
  disabled,
  isSalary,
  maxHeight,
  onBarClick,
  onClearFilter,
  selectedValue,
  title,
}) => (
  <div>
    <div style={{ margin: '0 auto', maxWidth: 350 }}>
      {!disabled && (
        <Icon
          type={selectedValue ? 'close-circle' : 'filter'}
          theme="twoTone"
          twoToneColor={selectedValue ? '#eb2f96' : ''}
          className="bar-icon"
          onClick={() => onClearFilter()}
        />
      )}
      {title && <Meta title={title} description={description} />}
      <Skeleton
        loading={!(data && !!data.length)}
        paragraph={{
          rows: 8,
          width: ['90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%'],
        }}
        title={false}
      >
        <SimpleBar
          data={data}
          disabled={!!disabled}
          isSalary={isSalary}
          onBarClick={onBarClick}
          selectedValue={selectedValue}
          maxHeight={maxHeight}
        />
      </Skeleton>
    </div>
  </div>
)

BarFilters.propTypes = {
  data: PT.array.isRequired,
  description: PT.string,
  disabled: PT.bool,
  isSalary: PT.bool,
  maxHeight: PT.number,
  onBarClick: PT.func,
  onClearFilter: PT.func,
  selectedValue: PT.string,
  title: PT.string.isRequired,
}

export default BarFilters
