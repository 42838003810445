import React, { useEffect, useState } from 'react'

import { Col, Result, Skeleton, Spin, Typography } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import truncate from 'lodash/truncate'

import { TREND_CATEGORIES } from '../..'
import { useOnUpdate } from '../../../../../../../hooks/useOnUpdate'
import usePrevious from '../../../../../../../hooks/usePrevious'
import { pickWithDefaults } from '../../../../../../../utils/data/utils'
import { capitalize } from '../../../../../../../utils/stringManipulation'
import HiringTrendsChart from '../../../../components/HiringTrendsChart'
import { useGraduateOutcomesTrendsData } from '../../../../hooks/useGraduateOutcomesTrendsData'
import { useGraduateOutcomesTrendsOptions } from '../../../../hooks/useGraduateOutcomesTrendsOptions'
import { useCurrentlyLoaded } from '../../../hooks/useCurrentlyLoaded'
import TrendsCard from '../TrendsCard'

const { Text } = Typography

export default function JobTrends(props) {
  const {
    clientName,
    finalTaxonomy,
    loadingTopLevelData,
    topLevelFilters,
    universitiesToSubmit,
    universityNameDisplay,
  } = pickWithDefaults(props, {
    clientName: null,
    finalTaxonomy: '',
    loadingTopLevelData: false,
    setShowingLoaderForTrendsTab: noop,
    showingLoaderForTrendsTab: false,
    topLevelFilters: {},
    universitiesToSubmit: [],
    universityNameDisplay: '',
  })

  const [selectedJobFunctionOption, setSelectedJobFunctionOption] = useState(null)
  const [loadingInitialTrends, setLoadingInitialTrends] = useState(true)
  const {
    loadingGraduateOutcomesTrendsOptions,
    graduateOutcomesTrendOptionsError,
    trendOptions,
    docCount,
  } = useGraduateOutcomesTrendsOptions({
    clientName,
    finalTaxonomy,
    topLevelFilters,
    trendCategory: TREND_CATEGORIES.JOB_FUNCTIONS,
    universitiesToSubmit,
  })

  const topJobFunctions = get(trendOptions, 'top_job_categories', [])

  const {
    getTrendsData,
    loadingTrendsData,
    trendsData,
    trendsDataError,
  } = useGraduateOutcomesTrendsData({
    setLoadingInitialTrends,
  })

  useOnUpdate(topJobFunctions, () => {
    if (topJobFunctions.length) {
      const firstOption = topJobFunctions[0]
      const topJobFunctionsName = get(firstOption, 'key')
      setSelectedJobFunctionOption(topJobFunctionsName)
    }
  })

  const filters = {
    ...omit(topLevelFilters, 'universities'),
    taxonomy: finalTaxonomy,
    universities: universitiesToSubmit,
  }
  const prevSelectedJobFunctionOption = usePrevious(selectedJobFunctionOption)
  const prevFilters = usePrevious(filters)
  useEffect(() => {
    if (
      selectedJobFunctionOption &&
      (!isEqual(prevSelectedJobFunctionOption, selectedJobFunctionOption) ||
        !isEqual(prevFilters, filters))
    ) {
      getTrendsData({
        variables: {
          category: TREND_CATEGORIES.JOB_FUNCTIONS,
          filters,
          targets: [selectedJobFunctionOption],
        },
      })
    }
  }, [
    filters,
    getTrendsData,
    prevFilters,
    prevSelectedJobFunctionOption,
    selectedJobFunctionOption,
  ])

  const handleJobFunctionClick = value => {
    if (loadingTrendsData) {
      return
    } else {
      if (value === selectedJobFunctionOption) setSelectedJobFunctionOption(null)
      else setSelectedJobFunctionOption(value)
    }
  }

  const jobTrends = get(trendsData, 'job_functions_hiring_trends', [])
  const loadedJobTrends = useCurrentlyLoaded(jobTrends)

  const loadingAll =
    loadingTopLevelData || loadingGraduateOutcomesTrendsOptions || loadingTrendsData
  const hasError = trendsDataError || graduateOutcomesTrendOptionsError
  const noResults = (!loadingAll && !topJobFunctions.length && !jobTrends.length) || hasError
  const lowVolume = !loadingAll && docCount < 100

  return (
    <TrendsCard>
      <Col md={8}>
        <Meta
          title="Job Functions"
          description="Click any job sector to display hiring trends on the right."
        />
        <div className="click-list">
          <Skeleton
            style={{ width: '80%' }}
            loading={loadingGraduateOutcomesTrendsOptions}
            paragraph={{ rows: 12 }}
            title={false}
          >
            {topJobFunctions.map((option, index) => {
              const { key: name } = pickWithDefaults(option, {
                key: '',
              })
              const isSelected = selectedJobFunctionOption === name
              const backgroundColor = isSelected
                ? 'rgb(80, 153, 222, 0.5)'
                : !name && index === 0
                  ? 'rgb(80, 153, 222, 0.5)'
                  : ''
              const nameDisplay =
                isSelected && loadingTrendsData ? (
                  <div style={{ height: 20, marginLeft: 8, opacity: 0.8 }}>
                    <Spin size="small" />
                  </div>
                ) : (
                  <div style={{ float: 'left' }}>
                    <Text>{capitalize(name)}</Text>
                  </div>
                )

              return (
                <div
                  key={`${name}${index}`}
                  className="click-list-item"
                  onClick={() => handleJobFunctionClick(name)}
                  style={{
                    backgroundColor,
                    clear: 'both',
                  }}
                >
                  <div>{nameDisplay}</div>
                </div>
              )
            })}
          </Skeleton>
        </div>
      </Col>

      <Col md={16}>
        <Meta
          title="Job Functions Hiring Trends"
          description="The job functions of your graduates, as a percentage of the total graduates. (20 year trend)"
        />
        {lowVolume ? (
          <Result
            title="Sample size is too small for trend analysis."
            subTitle="Please expand your filter criteria to show trends."
          />
        ) : noResults ? (
          <Result
            title="Sorry, no data available with current filters."
            subTitle="Please try clearing some filters."
          />
        ) : (
          <Spin spinning={loadingInitialTrends}>
            <HiringTrendsChart
              trends={loadedJobTrends}
              referenceKey={truncate(universityNameDisplay, {
                length: 66,
              })}
            />
          </Spin>
        )}
      </Col>
    </TrendsCard>
  )
}
