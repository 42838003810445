import React from 'react'

import { Button } from 'antd'
import PT from 'prop-types'

import colors from '../../../../components/hocs/colors'
import CompanyLogo from '../../../../components/media/CompanyLogo'
import useModalManagement from '../../../../hooks/useModalManagement'
import sendFullStoryEvent from '../../../../utils/analytics/sendFullStoryEvent'
import fullstory from '../../../../utils/constants/fullstory'
import { capitalize } from '../../../../utils/stringManipulation'
import CompanyDetailsCard from '../components/CompanyDetailsCard'
import CompanyHiringTrendsChart from '../components/CompanyHiringTrendsChart'

export const CompanyCard = ({
  hiringTrends,
  id,
  include_university_employees,
  name,
  skills,
  taxonomy,
  universitiesToSubmit,
  universityNameDisplay,
}) => {
  const companyDetailsModalProps = useModalManagement(false)

  return (
    <div className="company-card">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <CompanyLogo id={id} size={50} />
          <span className="company-card-name">{capitalize(name)}</span>
        </div>

        <div
          onClick={() => {
            const eventProperties = {
              company_name_str: name,
            }
            sendFullStoryEvent(fullstory.eventNames.benchmarkClick, eventProperties)
          }}
        >
          <Button
            type="primary"
            ghost
            onClick={companyDetailsModalProps.openModal}
            style={{
              color: colors.blue1,
              fontSize: 14,
            }}
          >
            <i className="fal fa-signal-alt fa-rotate-270" style={{ marginRight: 6 }} />
            Benchmark
          </Button>
        </div>
      </div>

      <div className="company-card-content" style={{ height: 100 }}>
        <CompanyHiringTrendsChart
          containerProps={{
            style: {
              bottom: 0,
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
            },
          }}
          data={hiringTrends}
        />
      </div>

      <div className="company-card-footer">
        <div style={{ paddingTop: 15 }}>
          {skills.length > 0 && <b>Top Skills: </b>}
          {skills && skills.map(skill => skill).join(', ')}
        </div>
      </div>
      <CompanyDetailsCard
        universitiesToSubmit={universitiesToSubmit}
        companyId={id}
        companyName={name}
        include_university_employees={include_university_employees}
        modalProps={companyDetailsModalProps}
        taxonomy={taxonomy}
        universityNameDisplay={universityNameDisplay}
      />
    </div>
  )
}

CompanyCard.propTypes = {
  glassDoorRating: PT.string,
  name: PT.string.isRequired,
  skills: PT.array,
  universitiesToSubmit: PT.arrayOf(PT.string),
  url: PT.string,
}

export default CompanyCard
