import React from 'react'

import PT from 'prop-types'
import CountUp from 'react-countup'

const StatCountUp = ({ data = 0, prefix, style, suffix, title }) => (
  <div style={style}>
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: 14,
        marginBottom: 3,
      }}
    >
      {title}
    </div>
    <CountUp
      prefix={prefix}
      separator=","
      start={0}
      end={data}
      style={{ color: 'rgba(0, 0, 0, 0.8)', fontSize: 24 }}
      suffix={suffix}
    />
  </div>
)

StatCountUp.propTypes = {
  data: PT.number,
  prefix: PT.string,
  style: PT.object,
  title: PT.string,
}

export default StatCountUp
