import { useLazyQuery } from '@apollo/client'
import get from 'lodash/get'
import noop from 'lodash/noop'

import { pickWithDefaults } from '../../../../utils/data/utils'
import { graduateOutcomesTrendsDataQuery } from '../gqlQueries'

export const useGraduateOutcomesTrendsData = props => {
  const { setLoadingInitialTrends } = pickWithDefaults(props, {
    setLoadingInitialTrends: noop,
  })
  const [getTrendsData, { data, loading, error }] = useLazyQuery(graduateOutcomesTrendsDataQuery, {
    fetchPolicy: 'network-only',
    onCompleted: () => setLoadingInitialTrends(false),
  })

  const trendsData = get(data, 'graduateOutcomesTrendsData')

  return {
    getTrendsData,
    loadingTrendsData: loading,
    trendsData,
    trendsDataError: error,
  }
}
