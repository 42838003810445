import React from 'react'

import get from 'lodash/get'
import PT from 'prop-types'

const PlayButton = props => {
  const additionalStyles = get(props, 'additionalStyles')
  const handleClick = get(props, 'handleClick')
  return (
    <i
      className="fa fa-play-circle tag-play-button-header"
      style={{
        ...{
          color: '#fc9b5e',
          cursor: 'pointer',
          fontSize: '21px',
          marginLeft: '5px',
          paddingTop: '14px',
        },
        ...additionalStyles,
      }}
      aria-hidden={true}
      onClick={handleClick}
    />
  )
}

PlayButton.propTypes = {
  additionalStyles: PT.object,
  handleClick: PT.func.isRequired,
}

export default PlayButton
