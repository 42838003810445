import { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import transform from 'lodash/transform'

import usePrevious from '../../../../hooks/usePrevious'
import { getFinalFacetsAndFiltersToOmit, pickWithDefaults } from '../../../../utils/data/utils'
import { graduateOutcomesOverviewQuery } from '../gqlQueries'

/** @typedef {import ('react').SetStateAction<ClickFilterOptions>} SetClickFilterOptions */

/** @typedef {import ('react').Dispatch<SetClickFilterOptions>} DispatchSetClickFilters */

const CLICK_PREFIX = 'c_'

/**
 * @typedef ClickFilterOption
 * @type {object}
 * @property {string} key
 * @property {number} doc_count
 * @property {number} metric
 */

/**
 * geoData
 */

/**
 * @typedef ClickFilterOptions
 * @type {object}
 * @property {Array<ClickFilterOption>} [c_companies]
 * @property {Array<ClickFilterOption>} [c_job_titles]
 * @property {Array<ClickFilterOption>} [c_majors]
 * @property {Array<ClickFilterOption>} [c_states]
 */

export const useGraduateOutcomesOverview = ({
  clientName,
  topLevelFilters,
  overviewClickFilters,
  multiSchoolDims,
  taxonomy,
  setOverviewRecordCount,
  universities,
}) => {
  /**
   * @type {[ClickFilterOptions, DispatchSetClickFilters]} click filter options
   */
  const [clickFilterOptions, setClickFilterOptions] = useState({})

  const { data, loading, error } = useQuery(graduateOutcomesOverviewQuery, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const overviewData = get(data, 'graduateOutcomesOverview')
      const overviewRecordCount = get(overviewData, 'doc_count')
      setOverviewRecordCount(overviewRecordCount)
    },
    skip: !clientName,
    variables: {
      filters: {
        ...overviewClickFilters,
        ...omit(topLevelFilters, 'universities'),
        taxonomy,
        universities,
      },
    },
  })

  const graduateOutcomesOverviewData = get(data, 'graduateOutcomesOverview', {})
  const suppliedFacets = {
    ...get(graduateOutcomesOverviewData, 'facets', {}),
  }
  const { suppliedFilters, customAggs } = pickWithDefaults(graduateOutcomesOverviewData, {
    customAggs: {},
    suppliedFilters: {},
  })

  const { suppliedFiltersToOmit, facets } = getFinalFacetsAndFiltersToOmit({
    multiSchoolDims,
    suppliedFacets,
  })

  const overviewData = pickWithDefaults(customAggs, {
    avgAge: 0,
    avgSalary: 0,
    clickFacets: {},
    geoData: {},
    pctMale: 0,
    total: 0,
  })

  /**
   * @type import('lodash').Dictionary<string>
   */
  const suppliedTopLevelFilters = omitBy(
    omit(suppliedFilters, suppliedFiltersToOmit),
    (value, key) => {
      return key.slice(0, 2) === CLICK_PREFIX || key === 'geoPoints'
    },
  )

  const prevSuppliedTopLevelFilters = usePrevious(suppliedTopLevelFilters)
  const prevSuppliedFilters = usePrevious(suppliedFilters)

  useEffect(() => {
    const clickFacets = get(overviewData, 'clickFacets')
    if (!isEqual(prevSuppliedTopLevelFilters, suppliedTopLevelFilters)) {
      setClickFilterOptions(clickFacets)
    } else if (!isEqual(prevSuppliedFilters, suppliedFilters)) {
      const clonedOptions = cloneDeep(clickFilterOptions)

      const newClickFilterOptions = transform(
        clonedOptions,
        (acc, curr, key) => {
          const clickedFacets = suppliedFilters[key]
          const categoryHasClickFilterApplied = clickedFacets && clickedFacets.length
          if (!categoryHasClickFilterApplied) {
            acc[key] = clickFacets[key] || []
          } else {
            acc[key] = curr || []
          }
        },
        {},
      )

      setClickFilterOptions(newClickFilterOptions)
    }
  }, [
    suppliedTopLevelFilters,
    suppliedFilters,
    overviewData,
    clickFilterOptions,
    prevSuppliedFilters,
    prevSuppliedTopLevelFilters,
  ])

  return {
    clickFilterOptions,
    facets,
    graduateOutcomesOverviewError: error,
    loadingGraduateOutcomesOverviewData: loading,
    overviewData,
    suppliedFilters,
    suppliedTopLevelFilters,
  }
}
