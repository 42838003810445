import React from 'react'

import { Button, Icon, Row } from 'antd'
import get from 'lodash/get'
import keys from 'lodash/keys'
import startCase from 'lodash/startCase'

import colors from '../../../../../components/hocs/colors'
import { pickWithDefaults } from '../../../../../utils/data/utils'

export default function Filters(props) {
  const defaultProps = {
    checkTopLevelFiltersExists: () => {},
    clearIndividualFilter: () => {},
    clearTopLevelFilters: () => {},
    facets: [],
    loadingGraduateOutcomesOverviewData: false,
    setFiltersDrawerVisible: () => {},
    topLevelFilters: [],
  }

  const {
    topLevelFilters,
    clearIndividualFilter,
    loadingGraduateOutcomesOverviewData,
    clearTopLevelFilters,
    facets,
    checkTopLevelFiltersExists,
    setFiltersDrawerVisible,
  } = pickWithDefaults(props, defaultProps)

  return (
    <Row type="flex" align="middle" justify="end" className="report-width">
      {keys(topLevelFilters)
        .filter(
          metric =>
            !!get(topLevelFilters, metric) &&
            !!get(topLevelFilters, metric, []).length &&
            metric !== '__typename',
        )
        .map((metric, index) => (
          <div
            key={index}
            onClick={() => clearIndividualFilter(metric)}
            style={{
              backgroundColor: '#5099DE',
              borderRadius: 32,
              color: '#fff',
              cursor: 'pointer',
              fontSize: 12,
              height: '60%',
              marginRight: 10,
              padding: '3px 10px',
            }}
          >
            <span style={{ marginRight: 5 }}>{`${startCase(metric)}: ${
              get(topLevelFilters, metric, []).length
            }`}</span>
            <Icon type="close-circle" theme="twoTone" style={{ fontSize: 12 }} />
          </div>
        ))}
      <Button
        icon="plus-circle"
        type="primary"
        loading={loadingGraduateOutcomesOverviewData}
        onClick={() => facets && keys(facets).length && setFiltersDrawerVisible(true)}
        shape="round"
        style={{ fontSize: 14 }}
      >
        Add Filter
      </Button>
      {checkTopLevelFiltersExists(topLevelFilters) && (
        <Button
          icon="close-circle"
          onClick={() => clearTopLevelFilters()}
          shape="round"
          style={{
            color: colors.red1,
            fontSize: 14,
            marginLeft: 10,
          }}
        >
          Clear All Filters
        </Button>
      )}
    </Row>
  )
}
