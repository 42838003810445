import React from 'react'
import WholePageLoader from '../../layout/WholePageLoader'

const Loadable = BaseComponent => props => (
  <React.Suspense fallback={<WholePageLoader />}>
    <BaseComponent {...props} />
  </React.Suspense>
)

export default Loadable
