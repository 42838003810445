import React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Form, Icon, Input, Select } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'
import _fp from 'lodash/fp'

import CustomButton from '../../../../../../../../../components/forms/CustomButton'
import useUniversityReportJobDepartmentList from '../../../../../../../../../hooks/useUniversityReportJobDepartmentList'
import errorService from '../../../../../../../../../utils/analytics/error'
import feedback from '../../../../../../../../../utils/feedback'

/**
 * Query to grab initial form values
 */
const univeristyClientUserProfileQuery = gql`
  query UniversityClientUserProfile {
    universityClientUserProfile {
      id
      firstName
      lastName
      department
      departmentDetail
      jobTitle
    }
  }
`

/**
 * Mutation to update user profile
 */
const updateMyUniversityClientUserMutation = gql`
  mutation UpdateMyUniversityClientUser($data: UpdateMyUniversityClientUserInput!) {
    updateMyUniversityClientUser(data: $data) {
      id
      firstName
      lastName
      department
      departmentDetail
      jobTitle
    }
  }
`

const getInitialValues = _fp.pipe(
  _fp.get('universityClientUserProfile'),
  _fp.pick(['firstName', 'lastName', 'jobTitle', 'department', 'departmentDetail']),
)

const BasicInfoForm = props => {
  const { data } = useQuery(univeristyClientUserProfileQuery)
  const [runMutation] = useMutation(updateMyUniversityClientUserMutation)

  const { jobDepartmentList, loading } = useUniversityReportJobDepartmentList()
  const initialValues = getInitialValues(data)

  const { getFieldDecorator } = props.form

  const onSubmit = async e => {
    e.preventDefault()
    props.form.validateFields().then(async values => {
      try {
        await runMutation({ variables: { data: values } })
        feedback.success({ content: 'Profile updated successfully' })
      } catch (error) {
        errorService.report(error, 'BasicInfoForm')
        feedback.error({
          content: error.message,
          title: 'Error updating profile',
        })
      }
    })
  }

  const memoizedOnSubmit = _.memoize(onSubmit)

  return (
    <Form onSubmit={memoizedOnSubmit} hideRequiredMark>
      <Form.Item label="First Name">
        {getFieldDecorator('firstName', {
          initialValue: initialValues.firstName,
          rules: [
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(<Input name="firstName" autoFocus={true} prefix={<Icon type="user" />} />)}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator('lastName', {
          initialValue: initialValues.lastName,
          rules: [
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(<Input name="lastName" prefix={<Icon type="user" />} />)}
      </Form.Item>
      <Form.Item label="Job Title">
        {getFieldDecorator('jobTitle', {
          initialValue: initialValues.jobTitle,
          rules: [
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(<Input name="jobTitle" />)}
      </Form.Item>
      <Form.Item label="Department">
        {getFieldDecorator('department', {
          initialValue: initialValues.department,
          rules: [{ message: 'A value is required', required: true }],
        })(
          <Select>
            {jobDepartmentList &&
              !!jobDepartmentList.length &&
              jobDepartmentList.map((option, index) => (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="Department Detail">
        {getFieldDecorator('departmentDetail', {
          initialValue: initialValues.departmentDetail,
          rules: [
            {
              validator: () => {
                const { department, departmentDetail } = props.form.getFieldsValue([
                  'department',
                  'departmentDetail',
                ])
                return new Promise((resolve, reject) => {
                  if (department === 'Other' && (!departmentDetail || departmentDetail === '')) {
                    reject('A value is required')
                  } else {
                    resolve()
                  }
                })
              },
            },
          ],
        })(<Input name="departmentDetail" />)}
      </Form.Item>
      <CustomButton
        loading={loading}
        content="Save Info"
        htmlType="submit"
        type="primary"
        style={{ marginTop: 16 }}
        block
      />
    </Form>
  )
}

BasicInfoForm.propTypes = {}

const wrappedForm = Form.create({ name: 'BasicInfoForm' })(BasicInfoForm)
export default wrappedForm
