import _ from 'lodash'
import _fp from 'lodash/fp'
import get from 'lodash/get'
import head from 'lodash/head'
import last from 'lodash/last'
import omitBy from 'lodash/omitBy'
import partition from 'lodash/partition'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import transform from 'lodash/transform'
import uniqueBy from 'lodash/uniqBy'

import { REPORT_TYPES } from '../../applicationConstants'
import { capitalize } from '../../stringManipulation'
import { getDemoDimNames } from '../../superUserDataInteraction'

/**
 * Function factory for extractors
 * @param {String} key
 */
export const extractKeyFromList = (key = '') => _fp.map(_fp.get(key))

/**
 * Gets ids from a list
 */
export const extractIdsFromList = extractKeyFromList('id')

/**
 * Gets keys from a list
 */
export const extractKeysFromList = extractKeyFromList('key')

/**
 * Checks if value has some length
 * @param {String|Array} val
 */
export const hasLength = (val = '') => val.length > 0

/**
 * Like lodash.pick but with default state
 * @param {Object} data
 * @param {Array} valuesToRetrieve
 */
export const pickWithInitialState = (data = {}, valuesToRetrieve = []) =>
  valuesToRetrieve.reduce((acc, valueToRetrieve) => {
    const [key, initialState] = valueToRetrieve
    acc[key] =
      _.get(data, key, initialState) === null ? initialState : _.get(data, key, initialState)
    return acc
  }, {})

/**
 * Like lodash.pick but with default values for the keys
 * @param {Object} data
 * @param {Object} defaults
 * @returns {Object}
 */
export const pickWithDefaults = (data = {}, defaults = {}) => {
  const objectWithDefaults = transform(
    defaults,
    (result, val, key) => {
      result[key] = data[key] ? data[key] : val
    },
    {},
  )
  return objectWithDefaults
}

/**
 * Converts words to a sentence
 */
export const toSentence = _fp.pipe(
  _fp.snakeCase,
  _fp.split('_'),
  _fp.map(_fp.capitalize),
  _fp.join(' '),
)

/**
 * Utility to separate out react and tableau reports in separate array from single array of reports
 */
export const separateOutReports = reports => {
  const reportsByType = partition(reports, r => r.type === REPORT_TYPES.react)
  let reactReports = head(reportsByType)
  let tableauReports = last(reportsByType)

  reactReports = uniqueBy(reactReports, r => r?.name)
  reactReports = sortBy(reactReports, report => (report?.name === 'Graduate Outcomes' ? 0 : 1))

  tableauReports = uniqueBy(tableauReports, r => r?.name)
  tableauReports = sortBy(tableauReports, report => (report?.name === 'Graduate Outcomes' ? 0 : 1))

  return {
    reactReports,
    tableauReports,
  }
}

export const createQueryStringForTalentSearch = params => {
  const searches = transform(
    params,
    (result, value, key) => {
      if (key !== 'location' && !!value) {
        if (!Array.isArray(value) || !!value.length) result.push(`${key}=${value}`)
      }
    },
    [],
  )

  if (params && params.location) {
    searches.push(`location_name=${get(params, 'location.name', '')}`)
    searches.push(`location_radius=${get(params, 'location.radius', 0)}`)
    searches.push(`location_lat=${get(params, 'location.geoData.lat', 0)}`)
    searches.push(`location_lng=${get(params, 'location.geoData.lng', 0)}`)
  }
  const query = `?${searches.join('&')}`
  return query
}

export const removeFilterOptionFromAllFacets = (facets, optionToRemove) => {
  const newFacets = reduce(
    facets,
    (acc, filterOptions, key) => {
      acc[key] = omitBy(filterOptions, filterOption => get(filterOption, 'key') === optionToRemove)
      return acc
    },
    {},
  )

  return newFacets
}

export const getFinalFacetsAndFiltersToOmit = ({ suppliedFacets, multiSchoolDims }) => {
  const demoDimNames = getDemoDimNames()
  // We need to omit some supplied filters if we don't want to show then as selected filters
  const suppliedFiltersToOmit = ['taxonomy']

  // Add multi school dims as facets to show up as filters in the right drawer
  // we don't want to show universities as filters if there is only one university
  if (demoDimNames && demoDimNames.length > 1) {
    suppliedFacets['universities'] = demoDimNames.map(u => {
      return { key: u }
    })
  } else if (
    multiSchoolDims &&
    multiSchoolDims.length > 1 &&
    (!demoDimNames || !demoDimNames.length) // This is to stop showing Universities filters in side bar when main account has multischooldims, but demo doesn't
  ) {
    suppliedFacets['universities'] = multiSchoolDims.map(u => {
      return { key: u }
    })
  } else {
    //omit universities as selected filter when there are no multi school dims
    suppliedFiltersToOmit.push('universities')
  }

  return { facets: suppliedFacets, suppliedFiltersToOmit }
}

const getFriendlyLevel = (level, major) => {
  const nonHSLevel = level && level.toLowerCase() !== 'high school' ? level : null
  const majorExcludingHighschool = major && major.toLowerCase() !== 'high school' ? major : null

  if (nonHSLevel && majorExcludingHighschool)
    return `${capitalize(nonHSLevel)} in ${capitalize(majorExcludingHighschool)}`
  if (nonHSLevel) return `${capitalize(nonHSLevel)} Degree`
  if (majorExcludingHighschool) return `${capitalize(majorExcludingHighschool)} Degree`
}

export const getEducationMilestoneDisplay = ({ level, major, university }) => {
  const friendlyLevel = getFriendlyLevel(level, major)
  const friendlyUniversity = university ? capitalize(university) : ''

  return { level: friendlyLevel, university: friendlyUniversity }
}
