import React, { useState } from 'react'

import { Query } from '@apollo/client/react/components'
import { Col, Modal, Row, Card, Tooltip } from 'antd'
import get from 'lodash/get'

import SpinnerWithUberFacts from '../../../../components/layout/SpinnerWithUberFacts'
import CompanyLogo from '../../../../components/media/CompanyLogo'
import { pickWithInitialState } from '../../../../utils/data/utils'
import { capitalize } from '../../../../utils/stringManipulation'
import BarFilters from '../../components/AuthenticatedLayout/components/ReportElements/BarFilters'
import { graduateOutcomesCompanyDetailsQuery } from '../gqlQueries'

import CompanyDetailsSkillsBarChart from './CompanyDetailsSkillsChart'

const { Meta } = Card

export const CompanyDetailsCard = ({
  universitiesToSubmit,
  universityNameDisplay,
  companyId,
  companyName,
  include_university_employees,
  modalProps,
  taxonomy,
}) => {
  const [topSchool, setTopSchool] = useState('')
  const [major, setMajor] = useState(null)

  const handleTopSchoolClick = value => {
    if (value === topSchool) setTopSchool('')
    else setTopSchool(value)
  }

  const clearTopSchoolFilter = () => setTopSchool('')

  const handleMajorClick = value => {
    if (value === major) setMajor(null)
    else setMajor(value)
  }

  const clearMajorFilter = () => setMajor('')

  return (
    <Modal
      {...modalProps}
      destroyOnClose
      footer={null}
      onCancel={modalProps.closeModal}
      width={1000}
    >
      <Query
        query={graduateOutcomesCompanyDetailsQuery}
        variables={{
          filters: {
            c_majors: major ? [major] : null,
            companies: [companyName],
            include_university_employees,
            taxonomy,
            universities: universitiesToSubmit,
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          const companyDetailsData = get(data, 'graduateOutcomesCompanyDetails', {})

          const { clickFacets, comparativeSkills } = pickWithInitialState(companyDetailsData, [
            ['clickFacets', []],
            ['comparativeSkills', []],
          ])

          const { clickMajors, clickUniversities } = pickWithInitialState(clickFacets, [
            ['clickMajors', {}],
            ['clickUniversities', []],
          ])

          return (
            <SpinnerWithUberFacts loading={loading}>
              <header
                className="employers-modal-header"
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  maxWidth: '90%',
                  position: 'relative',
                }}
              >
                <CompanyLogo id={companyId} size={60} />
                <div className="header-content">
                  <Meta
                    title={capitalize(companyName)}
                    description={`National Analytics for hiring trends at ${capitalize(
                      companyName,
                    )}`}
                  />
                </div>
              </header>
              <hr />
              <Row className="filter-section" style={{ marginTop: 30 }}>
                <Col md={12}>
                  <BarFilters
                    title="Top Schools"
                    description={`Most popular alma maters at ${companyName}`}
                    data={clickUniversities}
                    disabled={true}
                    maxHeight={230}
                    onBarClick={handleTopSchoolClick}
                    onClearFilter={clearTopSchoolFilter}
                    selectedValue={topSchool}
                  />
                </Col>
                <Col md={12}>
                  <BarFilters
                    title="Hiring by Major"
                    description={`Top majors hired by ${capitalize(companyName)}`}
                    data={clickMajors}
                    disabled={false}
                    maxHeight={230}
                    onBarClick={handleMajorClick}
                    onClearFilter={clearMajorFilter}
                    selectedValue={major}
                  />
                </Col>
              </Row>
              <hr />
              <Row style={{ marginTop: 30 }}>
                <Col md={11} style={{ textAlign: 'right' }} className="ant-card-meta-title">
                  {capitalize(companyName)}
                </Col>
                <Col md={2} style={{ marginTop: 3, textAlign: 'center' }}>
                  vs.
                </Col>
                <Col md={11} className="ant-card-meta-title">
                  <Tooltip
                    placement="topLeft"
                    title={`${capitalize(universityNameDisplay)}`}
                  >{`${capitalize(universityNameDisplay)}`}</Tooltip>
                </Col>
                <Col md={24}>
                  <Meta
                    description="See the employer's top skills on the left, compared to the skills of your graduates on the right."
                    style={{ padding: '0 20px', textAlign: 'center' }}
                  />
                  <CompanyDetailsSkillsBarChart
                    containerProps={{
                      style: {
                        height: 600,
                        margin: '0 auto',
                        maxWidth: 800,
                        minWidth: 310,
                      },
                    }}
                    companyName={companyName}
                    data={comparativeSkills}
                    universityName={`${capitalize(universityNameDisplay)}`}
                    title=""
                  />
                </Col>
              </Row>
            </SpinnerWithUberFacts>
          )
        }}
      </Query>
    </Modal>
  )
}

export default CompanyDetailsCard
