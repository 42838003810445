import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MY_ACCOUNT_ROUTES } from '../../../../../utils/routing/routes'
import ChangePassword from './components/ChangePassword'
import BasicInfo from './components/BasicInfo'

const MyAccountNavigator = props => {
  return (
    <Switch>
      <Route
        path={MY_ACCOUNT_ROUTES.CHANGE_PASSWORD}
        component={ChangePassword}
      />
      <Route path={MY_ACCOUNT_ROUTES.BASIC_INFO} component={BasicInfo} />
    </Switch>
  )
}

export default MyAccountNavigator
