import { useState } from 'react'

import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { getTotalCountForUniversityQuery } from '../../routes/Authenticated/GraduateOutcomes/gqlQueries'
import {
  getDemoDimNames,
  getDemoSavedSearchName,
  getDemoUrl,
  getTaxonomy,
} from '../../utils/superUserDataInteraction'

const useUniversitiesForClient = ({
  clientName,
  multiSchoolDims,
  taxonomy,
  topLevelFilters,
  url,
}) => {
  const [grandTotal, setGrandTotal] = useState(0)

  const demoDimNames = getDemoDimNames()
  const demoUrl = getDemoUrl()
  const demoSavedSearchName = getDemoSavedSearchName()
  const demoTaxonomy = getTaxonomy()

  // use demo dim names first if set
  // then check for multi school dims at the client level if set
  // finally use clientName if no demo dim names or muti school dims
  const universitiesToSubmitForTotalCount =
    demoDimNames && !!demoDimNames.length
      ? demoDimNames
      : multiSchoolDims && !!multiSchoolDims.length
      ? multiSchoolDims
      : [clientName]

  const finalTaxonomy = demoTaxonomy ? demoTaxonomy : taxonomy
  // use demo dim names first if set
  // then check for multi school dims at the client level if set
  // finally use clientName if no demo dim names or muti school dims
  // use universities filters from topLevelFilters if subset of dim names are set
  const universitiesToSubmit =
    demoDimNames && !!demoDimNames.length
      ? get(topLevelFilters, 'universities', false) || demoDimNames
      : multiSchoolDims && !!multiSchoolDims.length
      ? get(topLevelFilters, 'universities', false) || multiSchoolDims
      : [clientName]

  const finalUrl = demoDimNames && !!demoDimNames.length ? demoUrl : url

  useQuery(getTotalCountForUniversityQuery, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const totalCount = get(data, 'getTotalCountForUniversity', 0)
      setGrandTotal(totalCount)
    },
    skip: !clientName,
    variables: {
      filters: {
        include_university_employees: get(topLevelFilters, 'include_university_employees', false),
        universities: universitiesToSubmitForTotalCount,
      },
    },
  })

  const universityNameDisplay = demoSavedSearchName
    ? demoSavedSearchName.split(',').join(', ')
    : demoDimNames.length
    ? demoDimNames
    : clientName

  return {
    finalTaxonomy,
    finalUrl,
    grandTotal,
    universities: universitiesToSubmit,
    universitiesToSubmitForTotalCount,
    universityNameDisplay,
  }
}

export default useUniversitiesForClient
