import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { List } from 'antd'

const Container = styled(List.Item)`
  border-bottom: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px 24px 8px 24px !important;
  width: 100%;
`

const AdminListItem = props => {
  const { email, fullName } = props
  return (
    <Container>
      <List.Item.Meta title={fullName} description={email} />
    </Container>
  )
}

AdminListItem.propTypes = {
  email: PT.string.isRequired,
  fullName: PT.string.isRequired
}

export default AdminListItem
