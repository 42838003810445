import React from 'react'

import SpinnerWithUberFacts from '../../../../../components/layout/SpinnerWithUberFacts'
import useModalManagement from '../../../../../hooks/useModalManagement'
import CompanyDetailsDrawer from '../../../components/CompanyDetailsDrawer'

import CompanyTrends from './components/CompanyTrends/'
import IndustryTrends from './components/IndustryTrends/'
import JobTrends from './components/JobTrends/'

export const TREND_CATEGORIES = {
  COMPANY: 'COMPANY',
  INDUSTRIES: 'INDUSTRIES',
  JOB_FUNCTIONS: 'JOB_FUNCTIONS',
}

const EmployerTrendsTab = ({
  universityNameDisplay,
  clientName,
  topLevelFilters,
  universityClientUserProfileLoading,
  finalTaxonomy,
  universitiesToSubmit,
  loadingGraduateOutcomesOverviewData,
}) => {
  const { visible, closeModal: closeDrawer } = useModalManagement(false)

  const loadingTopLevelData =
    loadingGraduateOutcomesOverviewData || universityClientUserProfileLoading

  return (
    <div className="card-content">
      <CompanyDetailsDrawer
        closeDrawer={closeDrawer}
        visible={visible}
        selectedCompany={'Facebook'}
      />
      <SpinnerWithUberFacts loading={loadingTopLevelData}>
        <IndustryTrends
          clientName={clientName}
          finalTaxonomy={finalTaxonomy}
          loadingTopLevelData={loadingTopLevelData}
          topLevelFilters={topLevelFilters}
          universitiesToSubmit={universitiesToSubmit}
          universityNameDisplay={universityNameDisplay}
        />
        <JobTrends
          clientName={clientName}
          finalTaxonomy={finalTaxonomy}
          loadingTopLevelData={loadingTopLevelData}
          topLevelFilters={topLevelFilters}
          universitiesToSubmit={universitiesToSubmit}
          universityNameDisplay={universityNameDisplay}
        />
        <CompanyTrends
          clientName={clientName}
          finalTaxonomy={finalTaxonomy}
          loadingTopLevelData={loadingTopLevelData}
          topLevelFilters={topLevelFilters}
          universitiesToSubmit={universitiesToSubmit}
          universityClientUserProfileLoading={universityClientUserProfileLoading}
          universityNameDisplay={universityNameDisplay}
        />
      </SpinnerWithUberFacts>
    </div>
  )
}

export default EmployerTrendsTab
