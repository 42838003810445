import React from 'react'
import PT from 'prop-types'
import { Card } from 'antd'

const ManageTeamFunctionalityContainer = props => {
  const { children, extra, title } = props
  return (
    <Card bodyStyle={{ paddingTop: 8 }} extra={extra} title={title}>
      {children}
    </Card>
  )
}

ManageTeamFunctionalityContainer.propTypes = {
  extra: PT.oneOfType([PT.element, PT.node]),
  title: PT.string.isRequired
}

export default ManageTeamFunctionalityContainer
