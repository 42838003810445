import React from 'react'
import { Switch } from 'antd'
import styled from 'styled-components'

const Container = styled.div``

const SwitchInput = props => {
  const { onChange, value } = props

  return (
    <Container>
      <Switch onChange={onChange} value={value} />
    </Container>
  )
}

SwitchInput.propTypes = {}

export default SwitchInput
