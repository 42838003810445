import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import head from 'lodash/head'
import last from 'lodash/last'
import size from 'lodash/size'
import includes from 'lodash/includes'
import styled from 'styled-components'
import { Row, Slider } from 'antd'
import { extractKeysFromList } from '../../../../../utils/data/utils'

const Container = styled.div`
  overflow-y: auto;
  max-height: 500px;
`

const getValueInSalaryFormat = value => `${Math.round(value / 1000)}k`

const RangeFilters = props => {
  const { metric, onChange, options, selectedFilters } = props

  /**
   * Selectable options (names) of the facet values
   */
  const selectableOptions = useMemo(() => extractKeysFromList(options), [
    options
  ])

  const [selectedRange, setSelectedRange] = useState(
    selectedFilters ? selectedFilters : []
  )

  /**
   * When an particular range is altered
   */
  const onRangeAlteration = useCallback(alteredRange => {
    setSelectedRange(alteredRange)
  }, [])

  const isMoney = metric => includes(['incomes', 'estSalary', 'salary'], metric)

  /**
   * When the value changes, run the onChange func prop
   */
  useEffect(() => {
    onChange(selectedRange)
    // eslint-disable-next-line
  }, [selectedRange])

  const min = head(selectableOptions, 0)
  const max = last(selectableOptions, 0)

  const marks = {}
  marks[min] = min > 1000 && isMoney(metric) ? getValueInSalaryFormat(min) : min
  marks[max] = max > 1000 && isMoney(metric) ? getValueInSalaryFormat(max) : max

  selectedRange.forEach(rangeValue => {
    marks[rangeValue] =
      rangeValue > 1000 && isMoney(metric)
        ? getValueInSalaryFormat(rangeValue)
        : rangeValue
  })

  return (
    <Container>
      <Row>
        <Slider
          range
          marks={marks}
          min={min}
          max={max}
          step={(max - min) / (size(selectableOptions) - 1)}
          defaultValue={selectedRange.length ? selectedRange : [min, max]}
          onAfterChange={onRangeAlteration}
          style={{ margin: '5px 15px' }}
        />
      </Row>
    </Container>
  )
}

const OptionPT = PT.shape({
  id: PT.number,
  docCount: PT.number
})

RangeFilters.propTypes = {
  options: PT.arrayOf(OptionPT).isRequired
}

export default RangeFilters
