import React from 'react'
import Spinner from 'react-spinkit'
import styled from 'styled-components'

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const SSpinner = styled(Spinner)`
  transform: scale(3);
`

const WholePageLoader = () => (
  <Page>
    <SSpinner fadeIn="none" name="ball-clip-rotate-multiple" />
  </Page>
)

export default WholePageLoader
