import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import omit from 'lodash/omit'

import { graduateOutcomesSkillsQuery } from '../gqlQueries'

export const useGraduateOutcomesSkillsData = ({
  topLevelFilters,
  finalTaxonomy,
  universitiesToSubmit,
  clientName,
  setRecordCount,
}) => {
  const { data, loading, error } = useQuery(graduateOutcomesSkillsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const trendsDocCount = get(data, 'graduateOutcomesSkills.doc_count', 0)
      setRecordCount(trendsDocCount)
    },
    skip: !clientName,
    variables: {
      filters: {
        ...omit(topLevelFilters, 'universities'),
        taxonomy: finalTaxonomy,
        universities: universitiesToSubmit,
      },
    },
  })

  const skillsData = get(data, 'graduateOutcomesSkills.customAggs.skills', [])

  return {
    loadingSkillsData: loading,
    skillsData,
    skillsDataDataError: error,
  }
}
