import React from 'react'

import { Form, Icon, Input } from 'antd'
import get from 'lodash/fp/get'

import { getHelp, getValidateStatus } from '../../../utils/forms'

const getPrefix = get('prefix')

const FormTextInput = props => {
  const { input, label, meta, className, ...rest } = props
  const extraProps = {}

  // Handle Icon
  if (getPrefix(props)) {
    extraProps.prefix = <Icon type={getPrefix(props)} style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
  }

  return (
    <Form.Item
      hasFeedback
      help={getHelp(meta)}
      label={label}
      validateStatus={getValidateStatus(meta)}
    >
      <Input className={className} {...extraProps} {...input} {...rest} />
    </Form.Item>
  )
}

export default FormTextInput
