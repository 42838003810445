import React from 'react'

import { Mutation } from '@apollo/client/react/components'
import { Icon, List, Popconfirm, Switch } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'
import PT from 'prop-types'
import styled from 'styled-components'

import errorService from '../../../../../../../../../utils/analytics/error'
import feedback from '../../../../../../../../../utils/feedback'

const ActionContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Container = styled(List.Item)`
  border-bottom: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px 24px 8px 24px !important;
  width: 100%;
`

const disableUniversityClientUserAccessMutation = gql`
  mutation DisableUniversityClientUserAccess($data: DisableUniversityClientUserAccessInput!) {
    disableUniversityClientUserAccess(data: $data) {
      id
      accessDenied
    }
  }
`

const enableUniversityClientUserAccessMutation = gql`
  mutation EnableUniversityClientUserAccess($data: EnableUniversityClientUserAccessInput!) {
    enableUniversityClientUserAccess(data: $data) {
      id
      accessDenied
    }
  }
`

const disableUniversityClientUserAdminAccessMutation = gql`
  mutation DisableUniversityClientUserAdminAccess($data: DisableUniversityClientUserAccessInput!) {
    disableUniversityClientUserAdminAccess(data: $data) {
      id
      isAdmin
    }
  }
`

const enableUniversityClientUserAdminAccessMutation = gql`
  mutation EnableUniversityClientUserAdminAccess($data: EnableUniversityClientUserAccessInput!) {
    enableUniversityClientUserAdminAccess(data: $data) {
      id
      isAdmin
    }
  }
`

const deleteUserMutation = gql`
  mutation deleteUniversityClientUser($userIdToDelete: ID!) {
    deleteUniversityClientUser(userIdToDelete: $userIdToDelete)
  }
`

const MemberListItem = props => {
  const { accessDenied, email, fullName, id, isAdmin, refetchMembers } = props

  const onChange = (enableAccess, disableAccess) => async value => {
    try {
      const payload = { variables: { data: { id } } }
      if (value) {
        await enableAccess(payload)
      } else {
        await disableAccess(payload)
      }
      feedback.success({ content: 'Access updated successfully' })
    } catch (error) {
      errorService.report(error, 'update access')
      feedback.error({
        content: error.message,
        title: 'Error updating access',
      })
    }
  }

  const memoizedOnChange = _.memoize(onChange)

  const onAdminAccessChange = (enableAdminAccess, disableAdminAccess) => async value => {
    try {
      const payload = { variables: { data: { id } } }
      if (value) {
        await enableAdminAccess(payload)
      } else {
        await disableAdminAccess(payload)
      }
      refetchMembers()
      feedback.success({ content: 'Admin access updated successfully' })
    } catch (error) {
      errorService.report(error, 'update admin access')
      feedback.error({
        content: error.message,
        title: 'Error updating admin access',
      })
    }
  }

  const onDeleteUser = async deleteUser => {
    try {
      const payload = { variables: { userIdToDelete: id } }
      await deleteUser(payload)
      refetchMembers()
      feedback.success({ content: 'User deleted successfully' })
    } catch (error) {
      errorService.report(error, 'delete user')
      feedback.error({
        content: error.message,
        title: 'Error deleting user',
      })
    }
  }

  const memoizedOnAdminAccessChange = _.memoize(onAdminAccessChange)

  return (
    <Container>
      <List.Item.Meta title={fullName} description={email} />
      <ActionContainer style={{ marginRight: 55 }}>
        <Mutation mutation={disableUniversityClientUserAdminAccessMutation}>
          {disableAdminAccess => (
            <Mutation mutation={enableUniversityClientUserAdminAccessMutation}>
              {enableAdminAccess => (
                <Switch
                  defaultChecked={isAdmin}
                  value={isAdmin}
                  onChange={memoizedOnAdminAccessChange(enableAdminAccess, disableAdminAccess)}
                />
              )}
            </Mutation>
          )}
        </Mutation>
      </ActionContainer>
      <ActionContainer style={{ marginRight: 30 }}>
        <Mutation mutation={disableUniversityClientUserAccessMutation}>
          {disableAccess => (
            <Mutation mutation={enableUniversityClientUserAccessMutation}>
              {enableAccess => (
                <Switch
                  disabled={isAdmin}
                  defaultChecked={!accessDenied}
                  value={!accessDenied}
                  onChange={memoizedOnChange(enableAccess, disableAccess)}
                />
              )}
            </Mutation>
          )}
        </Mutation>
      </ActionContainer>
      <ActionContainer>
        <Mutation mutation={deleteUserMutation}>
          {deleteUser => (
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={() => onDeleteUser(deleteUser)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Icon style={{ fontSize: 20 }} type="delete" theme="twoTone" />
            </Popconfirm>
          )}
        </Mutation>
      </ActionContainer>
    </Container>
  )
}

MemberListItem.propTypes = {
  accessDenied: PT.bool.isRequired,
  email: PT.string.isRequired,
  fullName: PT.string.isRequired,
}

export default MemberListItem
