import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import errorService from '../../../../utils/analytics/error'
import { pickWithDefaults } from '../../../../utils/data/utils'
import { universityClientUserProfileQuery } from '../gqlQueries'

export const useUniversityClientUserProfile = () => {
  const { data, error, loading } = useQuery(universityClientUserProfileQuery, {
    fetchPolicy: 'network-only',
    onError: error => {
      errorService.report(error, 'universityClientUserProfileQuery')
    },
  })

  const universityClientUserProfile = get(data, 'universityClientUserProfile', {})

  const { clientName, gradExplorerPermission, multiSchoolDims, taxonomy, url } = pickWithDefaults(
    universityClientUserProfile,
    {
      clientName: '',
      gradExplorerPermission: 'DOWNLOAD_LIMITED',
      multiSchoolDims: null,
      taxonomy: 'general',
      url: null,
    },
  )

  return {
    clientName,
    gradExplorerPermission,
    multiSchoolDims,
    taxonomy,
    universityClientUserProfileError: error,
    universityClientUserProfileLoading: loading,
    url,
  }
}
