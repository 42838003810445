import React from 'react'

import { Typography } from 'antd'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'

import colors from '../../../../components/hocs/colors'
import { capitalize } from '../../../../utils/stringManipulation'

const { Title } = Typography

export const EmployeeBreakdownBars = ({ data = [], maxHeight = 300 }) => {
  const max = maxBy(data, 'docCount')
  const title = get(data, 'key')

  return (
    <>
      {data && !!data.length ? (
        <div>
          <Title level={3} style={{ textAlign: 'center' }}>
            {title}
          </Title>

          <div
            style={{
              margin: '0 auto',
              maxHeight: maxHeight,
              maxWidth: 350,
              overflow: 'auto',
              paddingBottom: 20,
            }}
            className="scrolling-container"
          >
            {data.map((child, index) => {
              const key = get(child, 'key', '')
              const barWidth = `${(get(child, 'docCount', 0) * 100) /
                get(max, 'docCount', 0)}%`

              return (
                <div key={`${child}${index}`}>
                  <div
                    style={{
                      borderRadius: 3,
                      maxWidth: '90%',
                      padding: '8px',
                      width: '90%',
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        textTransform: 'uppercase',
                      }}
                    >
                      {capitalize(key)}
                    </div>
                    <div
                      style={{
                        background: colors.blue2,
                        border: `1px solid ${colors.blue2}`,
                        width: barWidth,
                      }}
                      className="scale-bars"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
    </>
  )
}
