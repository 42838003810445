import React from 'react'
import { Row, Col } from 'antd'
import Colors from '../hocs/colors'

const MapBubbleLegend = () => {
  return (
    <div style={{ marginTop: 20 }}>
      <div className="caps-label">ALUMNI POPULATION</div>
      <Row className="map-bubble-container">
        <Col sm={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble1,
              width: 25,
              height: 25,
              borderRadius: 25,
              marginTop: 12
            }}
          />
          <div>least </div>
        </Col>
        <Col sm={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble2,
              width: 30,
              height: 30,
              marginTop: 8
            }}
          />
          <div>less </div>
        </Col>
        <Col sm={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble3,
              width: 37,
              height: 37,
              marginTop: 4
            }}
          />
          <div>more </div>
        </Col>
        <Col sm={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble4,
              width: 44,
              height: 44
            }}
          />
          <div>most </div>
        </Col>
      </Row>
    </div>
  )
}

export default MapBubbleLegend
