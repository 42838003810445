import React from 'react'
import { Row, Col } from 'antd'
import StatCountUp from '../../components/AuthenticatedLayout/components/ReportElements/StatCountUp'

const OverviewStats = ({ avgAge, avgSalary, pctMale, total, topTotal }) => {
  return (
    <Row className="text-center" style={{ marginBottom: 0 }}>
      <Col span={6}>
        <StatCountUp
          data={total}
          title={total !== topTotal ? 'Filtered Alumni' : 'Alumni'}
        />
      </Col>
      <Col span={6}>
        <StatCountUp data={avgAge} title="Avg. Age" />
      </Col>
      <Col span={6}>
        <StatCountUp
          data={Math.round(avgSalary)}
          title="Avg. Salary"
          prefix="$"
        />
      </Col>
      <Col span={6}>
        <Col span={4}>
          <StatCountUp data={Math.round(pctMale)} title="Male" suffix="%" />
        </Col>
        <Col span={1}>
          <span>/</span>
        </Col>
        <Col span={4}>
          <StatCountUp
            data={Math.round(total === 0 ? 0 : 100 - pctMale)}
            title="Female"
            suffix="%"
          />
        </Col>
      </Col>
    </Row>
  )
}

export default OverviewStats
