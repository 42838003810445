import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import get from 'lodash/get'

export const universityReportJobDepartmentListQuery = gql`
  query UniversityReportJobDepartmentList {
    getUniversityReportJobDepartmentList
  }
`

const useUniversityReportJobDepartmentList = () => {
  const { data, error, loading } = useQuery(universityReportJobDepartmentListQuery)

  const jobDepartmentList = get(data, 'getUniversityReportJobDepartmentList', [])

  return { error, jobDepartmentList, loading }
}

export default useUniversityReportJobDepartmentList
