import React from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Authenticated from './Authenticated'
import Unauthenticated from './Unauthenticated'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/app" component={Authenticated} />
      <Route component={Unauthenticated} />
    </Switch>
  </BrowserRouter>
)

export default Routes
