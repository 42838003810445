import React, { useState } from 'react'
import get from 'lodash/get'
import differenceWith from 'lodash/differenceWith'
import { Popconfirm, Popover, Select, Button, Divider, Tooltip } from 'antd'

const ReassignTeamSelect = props => {
  const member = get(props, 'member')
  const teams = get(props, 'teams')
  const handleRemoveMemberClick = get(props, 'handleRemoveMemberClick')
  const reassignTeamMember = get(props, 'reassignTeamMember')

  const teamsAreEqual = (team1, team2) => team1.id === team2.id

  // teams of which the user is not a member
  const availableTeams = differenceWith(
    teams,
    get(member, 'teams'),
    teamsAreEqual
  )

  const [teamSelectedForAssignment, setTeamSelectedForReassignment] = useState(
    null
  )

  const selectTeam = teamName => {
    const selectedTeam = teams.find(team => team.name === teamName)
    setTeamSelectedForReassignment(selectedTeam)
  }

  const confirmMsg = `Reassign ${get(member, 'firstName')} ${get(
    member,
    'lastName'
  )} to ${get(teamSelectedForAssignment, 'name')}?`

  const handleConfirmClick = () => {
    const newTeamId = get(teamSelectedForAssignment, 'id')
    reassignTeamMember(member, newTeamId)
  }

  return (
    <>
      <Popover
        trigger="click"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <Select
              disabled={!availableTeams.length}
              style={{ minWidth: '200px' }}
              onSelect={teamName => selectTeam(teamName)}
              placeholder={!availableTeams.length ? 'No available teams' : null}
            >
              {availableTeams.length ? (
                availableTeams.map(team => {
                  const teamName = get(team, 'name')

                  return (
                    <Select.Option key={teamName} value={teamName}>
                      {teamName}
                    </Select.Option>
                  )
                })
              ) : (
                <Select.Option key="No Available Teams" value={null}>
                  "No Available Teams"
                </Select.Option>
              )}
            </Select>
            <Popconfirm
              title={confirmMsg}
              onConfirm={handleConfirmClick}
              disabled={!teamSelectedForAssignment}
              okText="Yes"
            >
              <Button
                style={{
                  marginTop: 10,
                  width: 'fit-content'
                }}
                type="primary"
                disabled={!teamSelectedForAssignment}
                size="small"
              >
                Reassign
              </Button>
            </Popconfirm>
          </div>
        }
        title="Select a team"
      >
        <button className="false-anchor">Reassign</button>
      </Popover>
      <Divider type="vertical" />
      {!!member.warning ?
         <Tooltip title="This member is only part of this team. Please reassign user to another team." >
           <Button type="link" disabled>Remove</Button>
         </Tooltip>
        :
        <Popconfirm
          title={
            <>
              <div>Remove {member.firstName}?</div>
            </>
          }
          onConfirm={() => handleRemoveMemberClick(member)}
          onCancel={e => e.stopPropagation()}
          okText="Yes"
          cancelText="No"
        >
          <button className="false-anchor">Remove</button>
        </Popconfirm>
      }
    </>
  )
}

export default ReassignTeamSelect
