import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MANAGE_TEAM_ROUTES } from '../../../../../utils/routing/routes'
import ManageMembers from './components/ManageMembers'
import ManageTeams from './components/ManageTeams'

const ManageTeamNavigator = props => {
  return (
    <Switch>
      <Route path={MANAGE_TEAM_ROUTES.MEMBERS} component={ManageMembers} />
      <Route path={MANAGE_TEAM_ROUTES.TEAMS} component={ManageTeams} />
    </Switch>
  )
}

export default ManageTeamNavigator
