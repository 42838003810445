import React from 'react'
import Highcharts from 'highcharts'
import highchartsSeriesLabel from 'highcharts/modules/series-label'
import highchartsExporting from 'highcharts/modules/exporting'
import highchartsExportData from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'
import colors from '../../../../components/hocs/colors'

highchartsSeriesLabel(Highcharts)
highchartsExporting(Highcharts)
highchartsExportData(Highcharts)

const CompanyHiringTrendsChart = ({ containerProps, data }) => {
  const options = {
    chart: {
      margin: [0, 0, 0, 0],
      style: {
        overflow: 'visible'
      },
      type: 'area'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      tickLength: 0
    },
    yAxis: {
      title: {
        text: null
      },
      maxPadding: 0,
      minPadding: 0,
      gridLineWidth: 0,
      ticks: false,
      endOnTick: false,
      labels: {
        enabled: false
      }
    },
    tooltip: {
      enabled: false
    },
    exporting: { enabled: false },
    plotOptions: {
      series: {
        enableMouseTracking: true,
        lineWidth: 4,
        lineColor: colors.blue1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 0
        }
      }
    },
    series: [
      {
        name: '',
        data,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, colors.blue1],
            [1, '#ffffff']
          ]
        }
      }
    ]
  }

  return (
    <div>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default CompanyHiringTrendsChart
