import React from 'react'

import { Col, Icon, Tooltip } from 'antd'

export default function SkillSorterColumn(props) {
  const { skillSortOption, setSelectedSkillsFilter, ...rest } = props
  const { displayName, propertyName, color, iconType, description } = skillSortOption

  return (
    <Col md={6} {...rest}>
      <span className="clickable" onClick={() => setSelectedSkillsFilter(propertyName)}>
        <Tooltip placement="top" title={description}>
          <Icon
            type={iconType}
            style={{
              color,
              fontSize: 18,
              opacity: 0.8,
              verticalAlign: 0,
            }}
          />
          <span className="legend-label">{displayName}</span>
        </Tooltip>
      </span>
    </Col>
  )
}
