import React from 'react'

import findKey from 'lodash/findKey'
import get from 'lodash/get'
import { Field } from 'react-final-form'

import FormCheckboxGroupInput from '../../../../../../../../../components/forms/FormCheckboxGroupInput'
import FormRadioGroupInput from '../../../../../../../../../components/forms/FormRadioGroupInput'
import { REPORTS_PERMISSIONS_LABELS } from '../../../../../../../../../utils/applicationConstants'

/**
 * Utility to render reports with permissions checkboxes in forms
 */
export const renderReportsWithPermissions = (
  reports,
  type = 'Checkbox',
  additionalStyles,
) =>
  reports.map(report => {
    return (
      <Field
        additionalStyles={additionalStyles}
        key={report?.id}
        name={`reports.${report.id}`}
        label={report?.name}
        component={
          type === 'Radio' && report?.name !== 'Talent Eye'
            ? FormRadioGroupInput
            : FormCheckboxGroupInput
        }
        options={get(report, 'allowedPermissions', [])}
        displayMappingFn={arrValues =>
          arrValues.map(arrValue => REPORTS_PERMISSIONS_LABELS[arrValue])
        }
        normalizeDataFn={arrValues =>
          arrValues.map(arrValue =>
            findKey(REPORTS_PERMISSIONS_LABELS, value => value === arrValue),
          )
        }
      />
    )
  })

export const isAdmin = (member = [], admins = []) => {
  return typeof admins.find(admin => admin.id === member.id) !== 'undefined'
}

export const removeAdminsFromList = (members = [], admins = []) => {
  const filteredMembers = members.filter(member => !isAdmin(member, admins))
  return filteredMembers
}
