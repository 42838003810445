import React from 'react'

import { Col, Icon, Row, Tooltip } from 'antd'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import PT from 'prop-types'

import colors from '../../components/hocs/colors'
import numberConverter from '../../utils/numberConverter'
import { capitalize } from '../../utils/stringManipulation'

export const SimpleBar = ({
  barColor,
  data = [],
  disabled,
  isSalary,
  maxHeight = 300,
  onBarClick,
  selectedValue,
}) => {
  const max = maxBy(data, 'doc_count')

  let maxSalary = 0
  try {
    maxSalary = maxBy(data, 'metric')
    maxSalary = get(maxSalary, 'metric', false)
  } catch (error) {
    console.log(error)
    maxSalary = false
  }

  // const maxSalary = get(head(data), 'avgSalary.doc_count', null)
  //   ? maxBy(data, 'avgSalary.doc_count')
  //   : false

  let computedBarcolor = isSalary ? colors.blue1 : colors.blue2
  computedBarcolor = barColor ? barColor : computedBarcolor

  return (
    <div
      style={{
        margin: '0 auto',
        maxHeight: maxHeight,
        maxWidth: 300,
        overflow: 'auto',
        paddingBottom: 20,
      }}
      className="scrolling-container"
    >
      {data.map((child, index) => (
        <Row
          key={`${JSON.stringify(child)}${index}`}
          type="flex"
          align="middle"
          style={{ marginBottom: '15px ' }}
        >
          <Col
            span={22}
            onClick={disabled ? null : () => onBarClick(get(child, 'key', ''))}
            style={{
              borderRadius: 3,
              cursor: disabled ? 'default' : 'pointer',
              marginLeft: '8px',
            }}
          >
            <Tooltip
              placement="topLeft"
              title={
                !!get(child, 'metric', 0) &&
                `Avg. Salary: $${numberConverter(get(child, 'metric', 0))}`
              }
            >
              <div
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                {capitalize(get(child, 'key', ''))}
              </div>
              {maxSalary ? (
                <div>
                  <div>
                    <div className="scale-bars-icon">
                      <Icon type="number" />
                    </div>
                    <div className="scale-bars-right">
                      <div
                        style={{
                          background:
                            selectedValue === get(child, 'key', '')
                              ? computedBarcolor
                              : selectedValue
                              ? colors.simpleBarNotClickedBackground
                              : computedBarcolor,
                          border: `1px solid ${computedBarcolor}`,
                          marginTop: 2,
                          width: `${(get(child, 'doc_count', 0) * 100) /
                            get(max, 'doc_count', 0)}%`,
                        }}
                        className="scale-bars"
                      />
                    </div>
                  </div>
                  {!!get(child, 'metric') && (
                    <div style={{ marginTop: 5 }}>
                      <div className="scale-bars-icon">
                        <Icon type="dollar" />
                      </div>
                      <div className="scale-bars-right">
                        <div
                          style={{
                            background:
                              selectedValue === get(child, 'key', '')
                                ? colors.salary
                                : selectedValue
                                ? colors.simpleBarNotClickedBackground
                                : colors.salary,
                            border: `1px solid ${colors.salary}`,
                            marginTop: 2,
                            width: `${(get(child, 'metric', 0) * 100) / maxSalary}%`,
                          }}
                          className="scale-bars"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    background:
                      selectedValue === get(child, 'key', '')
                        ? computedBarcolor
                        : selectedValue
                        ? colors.simpleBarNotClickedBackground
                        : computedBarcolor,
                    border: `1px solid ${computedBarcolor}`,
                    width: `${(get(child, 'doc_count', 0) * 100) / get(max, 'doc_count', 0)}%`,
                  }}
                  className="scale-bars"
                />
              )}
            </Tooltip>
          </Col>
        </Row>
      ))}
    </div>
  )
}

SimpleBar.propTypes = {
  barColor: PT.string,
  data: PT.array.isRequired,
  disabled: PT.bool,
  onBarClick: PT.func,
  selectedValue: PT.string,
}

export default SimpleBar
