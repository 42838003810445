import { message, notification } from 'antd'

const noop = () => {}

/**
 * Shows an error nitification
 * @param {Object} param
 */
const error = ({ content, duration = 3, title }) =>
  notification.error({
    description: content,
    duration,
    message: title
  })

/**
 * Shows an error nitification
 * @param {Object} param
 */
const info = ({ content, duration = 3, title }) =>
  notification.info({
    description: content,
    duration,
    message: title
  })

/**
 * Shows a simple success message
 * @param {Object} param
 */
const success = ({ content, duration = 4, onClose = noop, top }) => {
  message.config({
    top
  })
  message.success(content, duration, onClose)
}

export default {
  error,
  info,
  success
}
