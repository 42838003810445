import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from '../../../utils/graphql/configuration'

const withGraphQL = BaseComponent => props => (
  <ApolloProvider client={client}>
    <BaseComponent {...props} />
  </ApolloProvider>
)

export default withGraphQL
