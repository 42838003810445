import React from 'react'
import { Icon, Table, Tooltip } from 'antd'
import get from 'lodash/get'

const AddMembersTab = props => {
  const clientMembers = get(props, 'clientMembers')
  const teamName = get(props, 'teamName')
  const handleAddMemberClick = get(props, 'handleAddMemberClick')

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      render: (text, { lastName, warning }) => (
        <div>{`${text} ${lastName}`}</div>
      )
    },
    { title: 'Email', dataIndex: 'email' },
    {
      title: '',
      dataIndex: 'action',
      render: (text, record) => (
        <Tooltip title={`Add to ${teamName}`}>
          <Icon
            type="plus-circle"
            theme="twoTone"
            style={{ cursor: 'pointer' }}
            onClick={() => handleAddMemberClick(record)}
          />
        </Tooltip>
      )
    }
  ]

  return <Table dataSource={clientMembers} columns={columns} />
}

export default AddMembersTab
