import React from 'react'

import { Button, Card, Col, Icon, Result, Row } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'

import { TAXONOMIES } from '../../../../../../../utils/applicationConstants'
import { pickWithDefaults } from '../../../../../../../utils/data/utils'
import CompanyCard from '../../../../components/CompanyCard'
import CompanyTrendSorter from '../../../../components/CompanyTrendSorter'
import { COMPANY_OPTIONS_PAGE_SIZE, useCompanyTrendsData } from '../../hooks/useCompanyTrends'

import CompanySkeletons from './CompanySkeletons'

export default function CompanyTrends(props) {
  const {
    clientName,
    finalTaxonomy,
    include_university_employees,
    loadingTopLevelData,
    taxonomy,
    topLevelFilters,
    universitiesToSubmit,
    universityNameDisplay,
  } = pickWithDefaults(props, {
    clientName: null,
    finalTaxonomy: TAXONOMIES.GENERAL,
    include_university_employees: false,
    loadingTopLevelData: false,
    taxonomy: TAXONOMIES.GENERAL,
    topLevelFilters: {},
    universitiesToSubmit: [],
    universityNameDisplay: '',
  })

  const {
    companies,
    setCompanySortOption,
    selectedSortOption,
    totalCount,
    page,
    pageUp,
    loadingGraduateOutcomesTrendsOptions,
    graduateOutcomesTrendOptionsError,
    trendsDataError,
    loadingTrendsData,
    docCount,
  } = useCompanyTrendsData({
    clientName,
    finalTaxonomy,
    topLevelFilters,
    universitiesToSubmit,
  })

  const allCompaniesAlreadyShowing = page * COMPANY_OPTIONS_PAGE_SIZE >= totalCount

  const loadingAll =
    loadingTopLevelData || loadingGraduateOutcomesTrendsOptions || loadingTrendsData
  const hasError = trendsDataError || graduateOutcomesTrendOptionsError
  const noResults = (!loadingAll && !companies.length) || hasError
  const lowVolume = !loadingAll && docCount < 100

  return (
    <Card style={{ marginTop: 30 }} className="page-break-before">
      <CompanyTrendSorter currentCompanies={selectedSortOption} onChange={setCompanySortOption} />
      <Meta
        title="Top Employer Trends"
        description="Your alumni's new jobs at each company as a percent of new jobs in the company that year. (10 year trend)"
      />
      {lowVolume ? (
        <Result
          title="Sample size is too small for trend analysis."
          subTitle="Please expand your filter criteria to show trends."
        />
      ) : noResults ? (
        <Result
          title="Sorry, no data available with current filters."
          subTitle="Please try clearing some filters."
        />
      ) : (
        <Row style={{ marginTop: 20, textAlign: 'center' }} className="top-employers">
          <CompanySkeletons loading={loadingTrendsData} pageSize={COMPANY_OPTIONS_PAGE_SIZE}>
            {companies.map(company => {
              const { name, company_id, skills } = pickWithDefaults(company, {
                company_id: null,
                name: '',
                skills: [],
              })

              return (
                <Col key={name} md={12}>
                  <CompanyCard
                    name={name}
                    hiringTrends={get(company, 'trends', [])}
                    id={company_id}
                    include_university_employees={include_university_employees}
                    skills={skills}
                    taxonomy={taxonomy}
                    universitiesToSubmit={universitiesToSubmit}
                    universityNameDisplay={universityNameDisplay}
                  />
                </Col>
              )
            })}
          </CompanySkeletons>
          {!allCompaniesAlreadyShowing && (
            <Button
              size="large"
              onClick={pageUp}
              className="tag-load-more-companies load-more"
              style={{
                fontSize: 16,
              }}
            >
              Load More
              <Icon
                type="down-circle"
                style={{ fontSize: '18px', lineHeight: '14px' }}
                theme="filled"
              />
            </Button>
          )}
        </Row>
      )}
    </Card>
  )
}
