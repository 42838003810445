import React from 'react'

import { Radio, Icon } from 'antd'

const SkillSorter = ({ selectedSkillsFilter, onChangeSkillsType, skillsSortOptions }) => {
  return (
    <div>
      <Radio.Group onChange={e => onChangeSkillsType(e.target.value)}>
        {skillsSortOptions.map(skillSortOption => {
          const { color, displayName, iconType, propertyName } = skillSortOption
          return (
            <Radio.Button
              checked={selectedSkillsFilter === propertyName}
              value={propertyName}
              key={displayName}
            >
              <Icon
                type={iconType}
                style={{
                  color,
                  fontSize: 18,
                  opacity: 0.8,
                  verticalAlign: '0.15em',
                }}
              />
              <span className="legend-label">{displayName}</span>
            </Radio.Button>
          )
        })}
      </Radio.Group>
    </div>
  )
}

export default SkillSorter
