import React from 'react'

import { Skeleton, Col } from 'antd'
import range from 'lodash/range'

import { pickWithDefaults } from '../../../../../../../utils/data/utils'

const CompanySkeletons = props => {
  const { pageSize, loading, children } = pickWithDefaults(props, {
    children: <div />,
    loading: false,
    pageSize: 10,
  })

  const skeletons = range(pageSize).map((num, i) => (
    <Col key={i} md={12}>
      <Skeleton
        title
        paragraph={{ rows: 5 }}
        active
        avatar={{ shape: 'square' }}
        className="company-card"
      />
    </Col>
  ))
  if (loading) {
    return skeletons
  } else return children
}

export default CompanySkeletons
