import React from 'react'

import { Drawer } from 'antd'

import CompanyView from './components/CompanyView'

const CompanyDetailsDrawer = props => {
  const { closeDrawer, visible, selectedCompany, width = 600 } = props

  return (
    <Drawer
      title="Company Details"
      placement="right"
      closable={true}
      destroyOnClose
      getContainer={false}
      onClose={closeDrawer}
      visible={visible}
      style={{ overflow: 'auto' }}
      width={width}
      zIndex={1002}
    >
      <CompanyView companyInfo={selectedCompany} />
    </Drawer>
  )
}

export default CompanyDetailsDrawer
