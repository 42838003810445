import React from 'react'
import PT from 'prop-types'
import { Checkbox, Form } from 'antd'
import identity from 'lodash/identity'
import { getHelp, getValidateStatus } from '../../../utils/forms'

const FormCheckboxGroupInput = props => {
  const {
    input,
    label,
    meta,
    options,
    displayMappingFn,
    normalizeDataFn,
    additionalStyles
  } = props
  const { onChange, value } = input

  const antdOptions = displayMappingFn(options)

  const onChangeFn = values => {
    const normalizedData = normalizeDataFn(values)
    onChange(normalizedData)
  }

  const antdValues = displayMappingFn(value ? value : [])

  return (
    <div style={{ marginLeft: 10, marginBottom: 10, fontWeight: 'bold' }}>
      <Form.Item
        hasFeedback
        help={getHelp(meta)}
        label={label}
        validateStatus={getValidateStatus(meta)}
        style={{
          ...{
            marginBottom: -10
          },
          ...additionalStyles
        }}
      />
      <div style={{ marginLeft: 15, fontWeight: 'normal' }}>
        <Checkbox.Group
          options={antdOptions}
          onChange={onChangeFn}
          value={antdValues}
        />
      </div>
    </div>
  )
}

FormCheckboxGroupInput.propTypes = {
  displayMappingFn: PT.func,
  normalizeDataFn: PT.func
}

FormCheckboxGroupInput.defaultProps = {
  displayMappingFn: identity,
  normalizeDataFn: identity
}

export default FormCheckboxGroupInput
