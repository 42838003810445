import { useEffect } from 'react'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { sendUserData } from '../..'
import usePrevious from '../../../../hooks/usePrevious'

export const useSendUserData = userData => {
  const prevData = usePrevious(userData)

  useEffect(() => {
    if (!isEqual(prevData, userData)) {
      const universityClientUserProfile = get(userData, 'universityClientUserProfile')
      if (universityClientUserProfile) {
        sendUserData(universityClientUserProfile)
      }
    }
  }, [userData, prevData])
}
