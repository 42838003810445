export default {
  classNames: {
    currencyInput: 'currency-input',
    dateInput: 'date-input',
    searchbar: 'searchbar',
    selectInput: 'select-input',
    textAreaInput: 'textarea-input',
    textInput: 'text-input',
  },
  eventNames: {
    addTopLevelFilters: 'Add Top Level Filters',
    alumniPopulationReportClick: 'Alumni Population Report Click',
    benchmarkClick: 'Benchmark Click',
    clearClickFilter: 'Clear Click Filter',
    clearTopLevelFilters: 'Clear Top Level Filters',
    downloadCSV: 'Download CSV',
    filterByEmployers: 'Filter by Employers',
    filterByIndustries: 'Filter by Industries',
    filterByJobTitles: 'Filter by Job Titles',
    filterByMajors: 'Filter by Majors',
    filterBySkills: 'Filter by Skills',
    graduateDataTabClick: 'Graduate Data Tab Click',
    helpClick: 'Help Click',
    mapZoom: 'Map Zoom',
    signup: 'Sign Up',
    skillsClick: 'Skills Click',
    trendingClick: 'Trending Click',
    videoClick: 'Video Click',
  },
}
