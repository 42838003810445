import React, { useEffect, useState } from 'react'

import { Button, Card, Col, Icon, Result, Row, Skeleton } from 'antd'
import get from 'lodash/get'
import head from 'lodash/head'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import slice from 'lodash/slice'

import colors from '../../../../../components/hocs/colors'
import SpinnerWithUberFacts from '../../../../../components/layout/SpinnerWithUberFacts'
import usePrevious from '../../../../../hooks/usePrevious'
import sendFullStoryEvent from '../../../../../utils/analytics/sendFullStoryEvent'
import fullstory from '../../../../../utils/constants/fullstory'
import { getSelectedSkillsFilter } from '../../../../../utils/stringManipulation'
import SkillsBars from '../../components/SkillsBars'
import SkillSorter from '../../components/SkillSorter'
import { useGraduateOutcomesSkillsData } from '../../hooks/useGraduateOutcomesSkills'

import SkillSorterColumn from './components/SkillSorterColumn'

const { Meta } = Card

const BATCH_LENGTH = 20

const SKILLS_SORT_OPTIONS = [
  {
    color: colors.orange1,
    description: ' The most frequently occurring skills among your graduates.',
    displayName: 'Volume',
    iconType: 'fire',
    propertyName: 'topSkills',
  },
  {
    color: colors.blue1,
    description:
      'How significant these skills are to your graduates, compared against the general population.',
    displayName: 'Relevance',
    iconType: 'block',
    propertyName: 'relevantSkills',
  },
  {
    color: colors.green1,
    description: 'The skills most likely to align with higher salary outcomes.',
    displayName: 'Salary',
    iconType: 'dollar',
    propertyName: 'highestPayingSkills',
  },
]

const SkillsTab = ({
  topLevelFilters,
  finalTaxonomy,
  universitiesToSubmit,
  clientName,
  setRecordCount,
}) => {
  const [selectedSkillsFilter, setSelectedSkillsFilter] = useState('topSkills')
  const [skillsPage, setSkillsPage] = useState(1)

  const { skillsData, loadingSkillsData, skillsDataDataError } = useGraduateOutcomesSkillsData({
    clientName,
    finalTaxonomy,
    setRecordCount,
    topLevelFilters,
    universitiesToSubmit,
  })

  const skillsWithSalary = skillsData.filter(skill => !!skill.salary)

  const highestPayingSkills = orderBy(skillsWithSalary, ['salary'], ['desc'])
  const relevantSkills = orderBy(skillsData, ['relevance'], ['desc'])
  const topSkills = orderBy(skillsData, ['popularity'], ['desc'])

  const maxPopularity = get(head(topSkills), 'popularity')
  const maxRelevancy = get(head(relevantSkills), 'relevance')
  const maxSalary = get(head(highestPayingSkills), 'salary')

  const sortedSkills = {
    highestPayingSkills,
    relevantSkills,
    topSkills,
  }

  const selectedSkillsFilterData = get(sortedSkills, selectedSkillsFilter, [])

  const topFiveSkills = selectedSkillsFilterData.slice(0, 5).flatMap(skill => skill.name)

  const prevCurrentSkills = usePrevious(selectedSkillsFilter)
  useEffect(() => {
    if (prevCurrentSkills && selectedSkillsFilter && prevCurrentSkills !== selectedSkillsFilter) {
      const selectedSortOption = getSelectedSkillsFilter(selectedSkillsFilter)
      const eventProperties = {
        skill_sorter_str: selectedSortOption,
        top_five_strs: topFiveSkills,
      }

      sendFullStoryEvent(fullstory.eventNames.skillsClick, eventProperties)
    }
  }, [selectedSkillsFilter, prevCurrentSkills, topFiveSkills])

  const onChangeSkillsType = value => {
    setSelectedSkillsFilter(value)
  }

  const getSkeletonRows = () => {
    const skeletonRows = []
    let i = 0
    while (i < 40) {
      if (i % 4 === 0) {
        skeletonRows.push(
          <Col span={6} key={i}>
            <Skeleton
              avatar={{ size: 'small' }}
              paragraph={{ rows: 0 }}
              title={{ width: '90%' }}
              className="skills-skeleton-with-avatar"
            />
          </Col>,
        )
      } else {
        skeletonRows.push(
          <Col span={6} key={i}>
            <Skeleton paragraph={{ rows: 0 }} title={{ width: '90%' }} />
          </Col>,
        )
      }
      i++
    }

    return skeletonRows
  }

  if (!loadingSkillsData && !skillsData.length) {
    return (
      <Result
        icon={<Icon type="frown" theme="twoTone" />}
        title="Sorry, no data available with current filters"
        subTitle="Please try clearing some filters"
      />
    )
  }

  if (skillsDataDataError) {
    return (
      <Result
        status="500"
        title="Hmm..."
        subTitle={`This is what they call "Technical difficulties"`}
      />
    )
  }
  return (
    <div className="card-content">
      <SpinnerWithUberFacts loading={loadingSkillsData}>
        <Card style={{ marginTop: 30 }}>
          <div style={{ float: 'right' }}>
            <SkillSorter
              selectedSkillsFilter={selectedSkillsFilter}
              onChangeSkillsType={onChangeSkillsType}
              skillsSortOptions={SKILLS_SORT_OPTIONS}
            />
          </div>
          <Meta
            avatar={
              <Icon type="block" style={{ color: colors.blue1, fontSize: 32, opacity: 0.8 }} />
            }
            title="Most Relevant Skills"
            description="The skills that occur most frequently among these alumni compared to national averages."
          />
          <Row style={{ marginBottom: 0, marginTop: 40, textAlign: 'center' }}>
            {SKILLS_SORT_OPTIONS.map((skillSortOption, i) => {
              return (
                <SkillSorterColumn
                  key={skillSortOption.displayName}
                  setSelectedSkillsFilter={setSelectedSkillsFilter}
                  skillSortOption={skillSortOption}
                  offset={i === 0 ? 6 : 0}
                />
              )
            })}
          </Row>
          <Row>
            {skillsData.length
              ? slice(selectedSkillsFilterData, 0, skillsPage * BATCH_LENGTH).map(
                  (skill, index) => (
                    <SkillsBars
                      key={`${get(skill, 'name')}${index}`}
                      name={get(skill, 'name')}
                      values={[
                        {
                          name: 'popularity',
                          value: round((get(skill, 'popularity') * 100) / maxPopularity),
                        },
                        {
                          name: 'relevancy',
                          // normalize to 70, and add 30 as a minimum
                          value: round((get(skill, 'relevance') * 70) / maxRelevancy) + 30,
                        },
                        {
                          name: 'salary',
                          value: round((get(skill, 'salary') * 100) / maxSalary),
                        },
                      ]}
                    />
                  ),
                )
              : getSkeletonRows()}
          </Row>
          <Row type="flex" justify="center">
            {skillsPage * BATCH_LENGTH < skillsData.length && (
              <Button
                size="large"
                className="tag-load-more-skills load-more"
                onClick={() => setSkillsPage(skillsPage + 1)}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 16,
                }}
              >
                Load More
                <Icon
                  type="down-circle"
                  style={{ fontSize: '18px', lineHeight: '14px' }}
                  theme="filled"
                />
              </Button>
            )}
          </Row>
        </Card>
      </SpinnerWithUberFacts>
    </div>
  )
}

export default SkillsTab
