import React from 'react'

import { Tag } from 'antd'

const PillList = ({ color, pills, title }) => {
  return (
    <>
      {pills.length > 0 && (
        <div className="pill-list">
          <div style={{ fontSize: 12 }}>{title}</div>
          {pills.map(pill => (
            <Tag key={pill} style={{ margin: '0 5px 5px 0' }} color={color}>
              {pill}
            </Tag>
          ))}
        </div>
      )}
    </>
  )
}

export default PillList
