import React, { useCallback } from 'react'

import { Button } from 'antd'
import get from 'lodash/get'
import PT from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import sendFullStoryEvent from '../../../../../../../../utils/analytics/sendFullStoryEvent'
import fullstory from '../../../../../../../../utils/constants/fullstory'

const SButton = styled(Button)`
  margin: 0px 8px 0px 8px;
`

const HeaderLinkButton = props => {
  const { children, history, location, route } = props
  const pathname = get(location, 'pathname')
  const selected = route === '/app' ? pathname === route : pathname.startsWith(route)
  const navigate = useCallback(() => history.push(route), [history, route])
  return (
    <SButton
      style={{
        borderBottom: selected ? '4px solid #ff9552' : '',
        borderRadius: 0,
        color: selected ? '#000000' : '#4c4c4c',
        height: 61,
      }}
      type="link"
      onClick={() => {
        if (props.route === '/app/help') {
          const eventProperties = {
            previous_page_str: location.pathname,
          }
          sendFullStoryEvent(fullstory.eventNames.helpClick, eventProperties)
        }
        navigate()
      }}
    >
      {children}
    </SButton>
  )
}

HeaderLinkButton.propTypes = {
  route: PT.string.isRequired,
}

export default withRouter(HeaderLinkButton)
