import React from 'react'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { Query } from '@apollo/client/react/components'
import styled from 'styled-components'
import { Button, Empty, Typography } from 'antd'
import MembersList from './components/MembersList'
import AdminListItem from './components/AdminListItem'
import MemberListItem from './components/MemberListItem'
import CreateMemberForm from './components/CreateMemberForm'
import useModalManagement from '../../../../../../../hooks/useModalManagement'
import {
  pickWithInitialState,
  hasLength,
} from '../../../../../../../utils/data/utils'
import * as tags from './tags'
import PendingInvitationListItem from './components/PendingInvitationListItem'
import NoTeamsState from './components/NoTeamsState'

const EmptyState = styled(Empty)`
  margin: 24px !important;
`

/**
 * Gets pertinent fields from query result
 * @param {Object} data
 */
const getPertinentFields = (data = {}) => {
  const universityClient = get(data, 'universityClient')
  return pickWithInitialState(universityClient, [
    ['admins', []],
    ['availableSeats', []],
    ['invitations', []],
    ['members', []],
    ['name', ''],
    ['teams', []],
  ])
}

const ManageMembers = props => {
  const createMemberModalProps = useModalManagement(false)

  return (
    <Query query={tags.universityClientUserProfileQuery}>
      {currentUserData => {
        const currentUserId = get(
          currentUserData,
          'data.universityClientUserProfile.id',
        )
        return (
          <Query query={tags.univeristyClientMembersQuery}>
            {({ data, loading, refetch }) => {
              const {
                admins,
                invitations,
                members,
                name,
                teams,
              } = getPertinentFields(data)

              // Handles state when there are no teams
              if (!loading && !hasLength(teams)) {
                return <NoTeamsState />
              }

              return (
                <React.Fragment>
                  <MembersList
                    EmptyComponent={
                      <EmptyState description="No Pending Invitations" />
                    }
                    extra={
                      <>
                        <Button
                          type="primary"
                          onClick={createMemberModalProps.openModal}
                        >
                          Invite Member &nbsp;
                        </Button>
                      </>
                    }
                    data={invitations}
                    loading={loading}
                    title="Pending Invitations"
                    renderItem={({ item }) => (
                      <PendingInvitationListItem key={item.id} {...item} />
                    )}
                  />
                  <MembersList
                    containerProps={{ marginTop: 16 }}
                    data={admins}
                    EmptyComponent={
                      <EmptyState description="No Admins Found" />
                    }
                    loading={loading}
                    title="Admins"
                    renderItem={({ item }) => (
                      <AdminListItem key={item.id} {...item} />
                    )}
                  />
                  <MembersList
                    containerProps={{ marginTop: 16 }}
                    data={[
                      ...filter(admins, o => o.id !== currentUserId).map(o => {
                        return { ...o, key: o.id }
                      }),
                      ...members.map(o => {
                        return { ...o, key: o.id }
                      }),
                    ]}
                    EmptyComponent={<EmptyState description="No Users Found" />}
                    loading={loading}
                    title="Members"
                    renderItem={({ item }) => (
                      <MemberListItem
                        key={item.id}
                        {...item}
                        refetchMembers={refetch}
                      />
                    )}
                    extra={
                      <>
                        <Typography.Text
                          type="secondary"
                          style={{ marginRight: 40 }}
                        >
                          Is Admin?
                        </Typography.Text>
                        <Typography.Text
                          type="secondary"
                          style={{ marginRight: 40 }}
                        >
                          Enabled?
                        </Typography.Text>
                      </>
                    }
                  />
                  <CreateMemberForm
                    clientName={name}
                    modalProps={{
                      ...createMemberModalProps,
                      title: 'Invite Member',
                    }}
                    teams={teams}
                    refetch={refetch}
                    admins={admins}
                    members={members}
                    invitations={invitations}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

ManageMembers.propTypes = {}

export default ManageMembers
