import React, { useCallback } from 'react'
import { Button, Result, Card } from 'antd'
import { withRouter } from 'react-router-dom'
import { AUTHENTICATED_ROUTES } from '../../../../../../../../../utils/routing/routes'

const NoTeamsState = props => {
  const navigateToTeams = useCallback(
    () => props.history.push(AUTHENTICATED_ROUTES.MANAGE_TEAM),
    [props.history]
  )
  return (
    <Card bordered>
      <Result
        status="403"
        title="Hold On..."
        subTitle="You have to create a Team with secure permissions before you invite Users to your Organization."
        extra={
          <Button type="primary" onClick={navigateToTeams}>
            Manage Teams
          </Button>
        }
      />
    </Card>
  )
}

NoTeamsState.propTypes = {}

export default withRouter(NoTeamsState)
