import React from 'react'

import { Button, Card, Typography, Tabs, Divider } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import remove from 'lodash/remove'
import transform from 'lodash/transform'
import { Field, Form } from 'react-final-form'
import validator from 'validator'

import FormCheckboxGroupInput from '../../../../../../../../../components/forms/FormCheckboxGroupInput'
import FormTextInput from '../../../../../../../../../components/forms/FormTextInput'
import fullstory from '../../../../../../../../../utils/constants/fullstory'
import {
  hasLength,
  separateOutReports,
  extractIdsFromList,
} from '../../../../../../../../../utils/data/utils'
import { renderReportsWithPermissions, isAdmin } from '../utils'

import AddMembersTab from './AddMembersTab'
import CurrentUsersTab from './CurrentUsersTab'

const { Text } = Typography
const { TabPane } = Tabs

/**
 * Validation function
 * @param {Object} param
 */

const validate = ({ name = '' }) => {
  return {
    name: validator.isLength(name, { max: 250, min: 1 }) ? undefined : 'Invalid Team Name',
  }
}

const onlyOnOneTeam = ({ teams = [] }) => teams.length === 1

const isOnTeam = (currentMembersIds = [], candidateId) => {
  return currentMembersIds.includes(candidateId)
}

const isOnlyTeam = candidate => {
  return onlyOnOneTeam(candidate)
}

const withTeamWarning = member => ({
  ...member,
  warning: isOnlyTeam(member)
    ? 'This user belongs to no other teams. Please add this user to another team before removal.'
    : null,
})

const getCurrentTeamMembers = (clientMembers, currentMemberIds, admins) => {
  const teamMembers = transform(
    clientMembers,
    (result, member) => {
      if (!isAdmin(member, admins) && isOnTeam(currentMemberIds, member.id)) {
        result.push(withTeamWarning(member, currentMemberIds))
        return true
      } else {
        return true
      }
    },
    [],
  )
  return teamMembers
}

const getNonTeamMembers = (clientMembers, currentMemberIds, admins) => {
  const nonTeamMembers = transform(
    clientMembers,
    (result, member) => {
      if (!isAdmin(member, admins) && !isOnTeam(currentMemberIds, member.id)) {
        result.push(withTeamWarning(member, currentMemberIds))
        return true
      } else {
        return true
      }
    },
    [],
  )
  return nonTeamMembers
}

const getTeamsNotSelected = (teams, selectedTeam) => {
  const updatedTeams = cloneDeep(teams)
  remove(updatedTeams, team => team.name === selectedTeam.name)
  return updatedTeams
}

const EditTeamForm = props => {
  const {
    admins,
    documents,
    clientMembers = [],
    onSubmit,
    reports,
    allTeams,
    onReassignTeamSubmit,
    team,
    initialValues,
  } = props

  const teamsNotSelected = getTeamsNotSelected(allTeams, team)

  const addMemberToTeam = member => {
    const updatedMembers = cloneDeep(get(team, 'members'))
    updatedMembers.push(member)
    const updatedTeam = {
      id: get(team, 'id'),
      members: updatedMembers,
      name: get(team, 'name'),
      reports: get(team, 'reportPermissions'),
    }

    if (team.reportPermissions)
      updatedTeam['reports'] = team.reportPermissions.map(permission => {
        return {
          id: get(permission, 'report.id'),
          permissions: get(permission, 'permissions'),
        }
      })

    if (team.documentPermissions)
      updatedTeam['documents'] = team.documentPermissions.map(permission => {
        return {
          id: get(permission, 'document.id'),
          permissions: get(permission, 'permissions'),
        }
      })

    onSubmit(updatedTeam)
  }

  const removeMemberFromTeam = member => {
    const updatedMembers = cloneDeep(get(team, 'members'))
    remove(updatedMembers, mem => mem.id === member.id)
    const updatedTeam = {
      id: get(team, 'id'),
      members: updatedMembers,
      name: get(team, 'name'),
      reports: get(team, 'reportPermissions'),
    }

    if (team.reportPermissions)
      updatedTeam['reports'] = team.reportPermissions.map(permission => {
        return {
          id: get(permission, 'report.id'),
          permissions: get(permission, 'permissions'),
        }
      })

    if (team.documentPermissions)
      updatedTeam['documents'] = team.documentPermissions.map(permission => {
        return {
          id: get(permission, 'document.id'),
          permissions: get(permission, 'permissions'),
        }
      })

    onSubmit(updatedTeam)
  }

  const reassignTeamMember = (member, newTeamId) => {
    const currentTeamId = get(team, 'id', '')
    onReassignTeamSubmit(member, currentTeamId, newTeamId)
  }

  const currentTeamMembers = getCurrentTeamMembers(
    clientMembers,
    extractIdsFromList(team.members),
    admins,
  )

  const nonTeamMembers = getNonTeamMembers(clientMembers, extractIdsFromList(team.members), admins)

  const onUpdateTeamClick = formData => {
    const currentUpdatedTeam = {
      id: get(formData, 'id'),
      members: get(formData, 'members'),
      name: get(formData, 'name'),
    }

    if (formData.reports) {
      const reports = get(formData, 'reports', {})
      const finalReports = keys(reports).map(id => {
        return {
          id,
          permissions: get(reports, id),
        }
      })

      currentUpdatedTeam['reports'] = finalReports
    }

    if (formData.documents) {
      const documents = get(formData, 'documents', {})

      const finalDocuments = keys(documents).map(id => {
        return {
          id,
          permissions: get(documents, id),
        }
      })

      currentUpdatedTeam['documents'] = finalDocuments
    }

    onSubmit(currentUpdatedTeam)
  }

  const separatedReports = separateOutReports(reports)
  const reactReports = get(separatedReports, 'reactReports')
  const tableauReports = get(separatedReports, 'tableauReports')

  const isEmptyArray = value => {
    return Array.isArray(value) && !value.length
  }

  const removeEmpties = permissionsObject => {
    const filteredObject = omitBy(permissionsObject, isEmptyArray)
    return keys(filteredObject).length ? filteredObject : undefined
  }

  const cleanUpFormVals = formVals => {
    const hasNoValue = val => !val
    const documents = formVals.documents && removeEmpties(formVals.documents)
    const reports = formVals.reports && removeEmpties(formVals.reports)
    return omitBy(
      {
        ...formVals,
        ...{ documents: documents, reports: reports },
      },
      hasNoValue,
    )
  }

  const noChangesMade = formValues => {
    const formVals = pick(formValues, ['name', 'reports', 'documents'])
    const initialVals = pick(initialValues, ['name', 'reports', 'documents'])
    const finalFormVals = cleanUpFormVals(formVals)
    return isEqual(finalFormVals, initialVals)
  }

  return (
    <Form
      onSubmit={onUpdateTeamClick}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => {
        const noChanges = noChangesMade(values)
        return (
          <form onSubmit={handleSubmit}>
            <Card type="inner">
              <div>
                <Field
                  autoFocus
                  label="Team Name"
                  name="name"
                  component={FormTextInput}
                  className={fullstory.classNames.textInput}
                />
                <div style={{ padding: '0 8px' }}>
                  {hasLength(documents) && (
                    <Text
                      style={{
                        color: 'rgba(0, 0, 0, 0.8)',
                        display: 'block',
                        fontSize: 'small',
                        marginBottom: '5px',
                      }}
                    >
                      Documents Access & Permissions:
                    </Text>
                  )}
                  {documents.map(document => (
                    <Field
                      key={document.id}
                      name={`documents.${document.id}`}
                      label={document.name}
                      component={FormCheckboxGroupInput}
                      options={['DOWNLOAD']}
                      additionalStyles={{ marginBottom: -4 }}
                    />
                  ))}
                  {hasLength(reactReports) && (
                    <>
                      <Divider style={{ margin: '12px 0' }} />
                      <Text
                        style={{
                          color: 'rgba(0, 0, 0, 0.8)',
                          display: 'block',
                          fontSize: 'small',
                          marginBottom: '5px',
                        }}
                      >
                        Reports Access Permissions:
                      </Text>
                    </>
                  )}
                  {renderReportsWithPermissions(reactReports, 'Radio', {
                    marginBottom: -4,
                  })}
                  {hasLength(tableauReports) && (
                    <>
                      <Divider style={{ margin: '12px 0' }} />
                      <Text
                        style={{
                          color: 'rgba(0, 0, 0, 0.8)',
                          display: 'block',
                          fontSize: 'small',
                          marginBottom: '5px',
                        }}
                      >
                        Tableau Reports Access Permissions:
                      </Text>
                    </>
                  )}
                  {renderReportsWithPermissions(tableauReports, 'Checkbox', {
                    marginBottom: -4,
                  })}
                </div>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ float: 'right' }}
                  disabled={noChanges}
                >
                  Update Team
                </Button>
              </div>
              <div style={{ clear: 'both' }}>
                <Tabs type="line" size="small">
                  <TabPane
                    tab={
                      <>
                        <i className="fal fa-file-chart-line" /> Current Users
                      </>
                    }
                    key="1"
                  >
                    <CurrentUsersTab
                      teams={teamsNotSelected}
                      dataSource={currentTeamMembers}
                      handleRemoveMemberClick={removeMemberFromTeam}
                      reassignTeamMember={reassignTeamMember}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <i className="fal fa-file-chart-line" /> Add Members
                      </>
                    }
                    key="2"
                  >
                    <AddMembersTab
                      clientMembers={nonTeamMembers}
                      teamName={get(team, 'name')}
                      handleAddMemberClick={addMemberToTeam}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </form>
        )
      }}
    />
  )
}

EditTeamForm.propTypes = {}

export default EditTeamForm
