import React, { useState } from 'react'

import { Drawer, Tabs, Result } from 'antd'
import omit from 'lodash/omit'
import { withRouter } from 'react-router-dom'

import CustomReportFilters from '../../../components/forms/CustomReportFilters'
import VideoModal from '../../../components/media/VideoModal'
import PlayButton from '../../../components/touchables/PlayButton'
import useGogeFilters from '../../../hooks/useGogeFilters'
import useUniversitiesForClient from '../../../hooks/useUniversitiesForClient'
import sendFullStoryEvent from '../../../utils/analytics/sendFullStoryEvent'
import { TUTORIALS } from '../../../utils/applicationConstants'
import fullstory from '../../../utils/constants/fullstory'
import { removeFilterOptionFromAllFacets } from '../../../utils/data/utils'
import AuthenticatedLayout from '../components/AuthenticatedLayout'
import ReportHeader from '../components/AuthenticatedLayout/components/AuthenticatedHeader/ReportHeader'

import Filters from './components/Filters'
import { useGraduateOutcomesOverview } from './hooks/useGraduateOutcomesOverview'
import { useUniversityClientUserProfile } from './hooks/useUniversityClientUserProfile'
import EmployerTrendsTab from './tabs/EmployerTrends/'
import Overview from './tabs/Overview/'
import SkillsTab from './tabs/Skills'

const { TabPane } = Tabs

const tabKeys = {
  employers: 'employers',
  overview: 'overview',
  skills: 'skills',
}

const Outcomes = ({ history, location }) => {
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState(false)
  const [overviewRecordCount, setOverviewRecordCount] = useState(0)
  const [activeKey, setActiveKey] = useState(tabKeys.overview)
  const [videoModalShown, setVideoModalShown] = useState(false)
  const [topLevelFilters, setTopLevelFilters] = useState({})

  let currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

  if (!currentPath || currentPath === '' || currentPath === 'graduate-outcomes')
    currentPath = tabKeys.overview

  if (currentPath !== activeKey) setActiveKey(currentPath)

  const handleTabChange = key => {
    history.replace(`/app/graduate-outcomes/${key === tabKeys.overview ? '' : key}`)
  }

  const {
    clientName,
    multiSchoolDims,
    taxonomy,
    url,
    universityClientUserProfileLoading,
  } = useUniversityClientUserProfile()

  const {
    finalTaxonomy,
    universities: universitiesToSubmit,
    finalUrl,
    grandTotal,
    universityNameDisplay,
  } = useUniversitiesForClient({
    clientName,
    multiSchoolDims,
    taxonomy,
    topLevelFilters,
    url,
  })

  const {
    onChangeCustomFilters,
    checkTopLevelFiltersExists,
    handleClickFilterClick,
    clearClickFilter,
    handleGeoSelection,
    clearIndividualFilter,
    clearTopLevelFilters,
    overviewClickFilters,
    setRecordCount,
  } = useGogeFilters({ grandTotal, setTopLevelFilters, topLevelFilters })

  const {
    facets,
    overviewData,
    loadingGraduateOutcomesOverviewData,
    suppliedTopLevelFilters,
    graduateOutcomesOverviewError,
    clickFilterOptions,
  } = useGraduateOutcomesOverview({
    clientName,
    multiSchoolDims,
    overviewClickFilters,
    setOverviewRecordCount,
    taxonomy: finalTaxonomy,
    topLevelFilters,
    universities: universitiesToSubmit,
  })

  const handlePlayButtonClick = () => {
    setVideoModalShown(true)
    const eventProperties = {
      location_str: TUTORIALS.graduateOutcomes.title,
      video_src_str: TUTORIALS.graduateOutcomes.videoSrc,
    }
    sendFullStoryEvent(fullstory.eventNames.videoClick, eventProperties)
  }

  return (
    <AuthenticatedLayout>
      <div>
        <VideoModal
          visible={videoModalShown}
          onCancel={() => setVideoModalShown(false)}
          tutorial={TUTORIALS.graduateOutcomes}
        />
        <div>
          <div>
            <Drawer
              title="Filters"
              placement="right"
              closable={true}
              destroyOnClose
              getContainer={false}
              onClose={() => setFiltersDrawerVisible(false)}
              visible={filtersDrawerVisible}
              style={{ overflow: 'auto' }}
              width={300}
              className="right-facets"
            >
              <CustomReportFilters
                onChange={onChangeCustomFilters}
                onCloseDrawer={() => setFiltersDrawerVisible(false)}
                facets={omit(removeFilterOptionFromAllFacets(facets, 'high school'), '__typename')}
                suppliedFilters={suppliedTopLevelFilters}
              />
            </Drawer>
            <div className="card-container">
              <div className="report-header-container">
                <ReportHeader
                  universityNameDisplay={universityNameDisplay}
                  title="Graduate Outcomes "
                  description="Learn about the career outcomes of your alumni, and use the filters to answer specific questions."
                  recordCount={overviewRecordCount}
                  totalRecords={grandTotal}
                  url={finalUrl}
                  videoLink={
                    <PlayButton
                      handleClick={handlePlayButtonClick}
                      additionalStyles={{ marginLeft: '0' }}
                    />
                  }
                />
                <Filters
                  topLevelFilters={topLevelFilters}
                  clearIndividualFilter={clearIndividualFilter}
                  loadingGraduateOutcomesOverviewData={loadingGraduateOutcomesOverviewData}
                  clearTopLevelFilters={clearTopLevelFilters}
                  facets={facets}
                  checkTopLevelFiltersExists={checkTopLevelFiltersExists}
                  setFiltersDrawerVisible={setFiltersDrawerVisible}
                />
              </div>
              <div>
                <Tabs
                  activeKey={activeKey}
                  className="report-body"
                  onTabClick={key => handleTabChange(key)}
                >
                  <TabPane tab="Overview" key="overview">
                    {!loadingGraduateOutcomesOverviewData && graduateOutcomesOverviewError ? (
                      <Result
                        status="500"
                        title="Hmm..."
                        subTitle={`This is what they call "Technical difficulties"`}
                      />
                    ) : (
                      <div className="card-content">
                        <div className="data-card">
                          <Overview
                            universityClientUserProfileLoading={universityClientUserProfileLoading}
                            handleClickFilterClick={handleClickFilterClick}
                            clearClickFilter={clearClickFilter}
                            overviewData={overviewData}
                            loading={loadingGraduateOutcomesOverviewData}
                            overviewClickFilters={overviewClickFilters}
                            handleGeoSelection={handleGeoSelection}
                            clickFilterOptions={clickFilterOptions}
                          />
                        </div>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab="Employer Trends" key="employers">
                    <EmployerTrendsTab
                      universityNameDisplay={universityNameDisplay}
                      loadingGraduateOutcomesOverviewData={loadingGraduateOutcomesOverviewData}
                      clientName={clientName}
                      taxonomy={finalTaxonomy}
                      topLevelFilters={topLevelFilters}
                      finalTaxonomy={finalTaxonomy}
                      universitiesToSubmit={universitiesToSubmit}
                      universityClientUserProfileLoading={universityClientUserProfileLoading}
                    />
                  </TabPane>
                  <TabPane tab="Skills" key="skills">
                    <SkillsTab
                      universityClientUserProfileLoading={universityClientUserProfileLoading}
                      topLevelFilters={topLevelFilters}
                      finalTaxonomy={finalTaxonomy}
                      universitiesToSubmit={universitiesToSubmit}
                      clientName={clientName}
                      setRecordCount={setRecordCount}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default withRouter(Outcomes)
