import React, { useState } from 'react'

import { Affix, Button, Checkbox, Collapse, Row } from 'antd'
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import last from 'lodash/last'
import startCase from 'lodash/startCase'
import PT from 'prop-types'

import sendFullStoryEvent from '../../../utils/analytics/sendFullStoryEvent'
import { INCLUDE_UNIVERSITY_EMPLOYEES, RANGE_FILTERS } from '../../../utils/applicationConstants'
import fullstory from '../../../utils/constants/fullstory'

import CheckboxList from './components/CheckboxList'
import RangeFilters from './components/RangeFilters'

const { Panel } = Collapse

const CustomFilters = props => {
  const facets = get(props, 'facets')
  const suppliedFilters = get(props, 'suppliedFilters')
  const onChange = get(props, 'onChange')
  const onCloseDrawer = get(props, 'onCloseDrawer')
  const [filters, setFilters] = useState(suppliedFilters)
  const [disabled, setDisabled] = useState(false)

  const onChangeFilters = metric => selectedItems => {
    const newFilters = filters
    newFilters[metric] = selectedItems
    setFilters(newFilters)
  }

  const onChangeIncludeUniversityEmployees = value => {
    const newFilters = filters
    newFilters[INCLUDE_UNIVERSITY_EMPLOYEES] = value
    setFilters(newFilters)
  }

  const onSubmitFilters = () => {
    if (filters) {
      const {
        ages = [],
        cities = [],
        colleges = [],
        current_employers = [],
        current_job_categories = [],
        degree_levels = [],
        genders = [],
        grad_years = [],
        highest_degree_level_anywhere = [],
        include_university_employees = false,
        incomes = [],
        industries = [],
        majors = [],
        military_status = [],
        states = [],
        universities = [],
      } = filters
      const eventProperties = {
        ages_ints: ages,
        cities_strs: cities,
        colleges_strs: colleges,
        current_employers_strs: current_employers,
        current_job_categories_strs: current_job_categories,
        degree_levels_strs: degree_levels,
        genders_strs: genders,
        grad_years_ints: grad_years,
        highest_degree_level_anywhere_strs: highest_degree_level_anywhere,
        include_university_employees_bool: include_university_employees,
        incomes_ints: incomes,
        industries_strs: industries,
        majors_strs: majors,
        military_status_strs: military_status,
        states_strs: states,
        universities_strs: universities,
      }
      sendFullStoryEvent(fullstory.eventNames.addTopLevelFilters, eventProperties)
    }

    onChange(filters)
    onCloseDrawer()
  }
  return (
    <>
      <div style={{ minHeight: '100vh' }}>
        <Collapse bordered={false}>
          {keys(facets)
            .sort()
            .map((metricKey, index) => {
              const matchingFilter = get(suppliedFilters, metricKey)

              const lengthDescription =
                matchingFilter && matchingFilter.length > 0
                  ? includes(RANGE_FILTERS, metricKey)
                    ? `  (${head(matchingFilter)}-${last(matchingFilter)} selected)`
                    : `  (${matchingFilter.length} selected)`
                  : ''

              return (
                <Panel header={`${startCase(metricKey)}${lengthDescription}`} key={index}>
                  {includes(RANGE_FILTERS, metricKey) ? (
                    <RangeFilters
                      metric={metricKey}
                      onChange={onChangeFilters(metricKey)}
                      options={get(facets, metricKey, [])}
                      selectedFilters={
                        get(suppliedFilters, metricKey, [])
                          ? get(suppliedFilters, metricKey, [])
                          : []
                      }
                    />
                  ) : (
                    <CheckboxList
                      metric={metricKey}
                      onChange={onChangeFilters(metricKey)}
                      options={get(facets, metricKey, [])}
                      selectedFilters={
                        get(suppliedFilters, metricKey, [])
                          ? get(suppliedFilters, metricKey, [])
                          : []
                      }
                      disabled={disabled}
                      setDisabled={setDisabled}
                    />
                  )}
                </Panel>
              )
            })}
        </Collapse>
        <Row
          style={{
            borderBottom: '1px solid #d9d9d9',
            padding: 15,
          }}
        >
          <Checkbox
            defaultChecked={get(suppliedFilters, INCLUDE_UNIVERSITY_EMPLOYEES, false)}
            onChange={val => onChangeIncludeUniversityEmployees(get(val, 'target.checked'))}
          >
            Include University Employees
          </Checkbox>
        </Row>
      </div>
      <Affix offsetBottom={20}>
        <div
          style={{
            background: '#fff',
            left: 0,
            marginTop: 20,
            padding: '10px 16px',
            textAlign: 'right',
            width: '100%',
          }}
        >
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={onCloseDrawer}
          >
            Cancel
          </Button>
          <Button onClick={onSubmitFilters} type="primary" disabled={disabled}>
            Submit
          </Button>
        </div>
      </Affix>
    </>
  )
}

CustomFilters.propTypes = {
  onChange: PT.func.isRequired,
}

CustomFilters.defaultProps = {
  facets: [],
}

export default CustomFilters
